import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import { ScrollView } from "@cantonjs/react-scroll-view";
import EnergyIndepProg from "./energyIndepProg";
import GaugeGraphII from "./gaugeGraph";
import PriorityProgress from "./priorityProgress";
import NodeProgBars from "./nodesProgBars";

class PowerModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    /*let hwDefined = "";
    if ((parseInt(this.props.hwConfig) & 0x0001) === 1) hwDefined = "SimplHub";
    else if ((parseInt(this.props.hwConfig) & 0x0002) === 2)
      hwDefined = "SimplGateway";
    else if ((parseInt(this.props.hwConfig) & 0x0004) === 4)
      hwDefined = "SimplATS";
    else if ((parseInt(this.props.hwConfig) & 0x0008) === 8)
      hwDefined = "SimplMeter II";

    let fourgeeModule = "?";
    let fourgeeMAC = "?";
    let fourgeeLinkActive = "?";

    if ((parseInt(this.props.hwConfig) & 0x00010) >> 4 === 1)
      fourgeeModule = "4G Module Installed";
    else fourgeeModule = "No 4G Module Installed";
    if ((parseInt(this.props.hwConfig) & 0x0020) >> 5 === 1)
      fourgeeMAC = "4G MAC Initialized";
    else fourgeeMAC = "4G MAC not Initialized";
    if ((parseInt(this.props.hwConfig) & 0x0040) >> 6 === 1)
      fourgeeLinkActive = "4G Link is Active";
    else fourgeeLinkActive = "4G Link not Active";*/
    //console.log("Company Name:", this.props.companyName);

    // const height = window.innerHeight
    // console.log("Inner height", height);
    // let windowHeight = height > 900 ? 220 : height < 700 ? 850 : 420;

    const realTimeNumber = parseFloat(this.props.realTimeNumber);
    const intervalNumber = parseFloat(this.props.intervalNumber);
    const rollingIntervalNumber = parseFloat(this.props.rollingIntervalNumber);

    const demandLimit = parseInt(this.props.demandLimit);
    const smMeter1 = parseInt(this.props.smMeter1);
    const smMeter2 = parseInt(this.props.smMeter2);
    const batteryPower = 0;

    const gridPowerProgress = parseInt(this.props.gridPowerProgress);
    const solarPowerProgress = parseInt(this.props.solarPowerProgress);
    const battPowerProgress = parseInt(this.props.battPowerProgress);
    const energyIndependence = parseInt(this.props.energyIndependence);

    const divTop = parseInt(this.props.nodePriorityTop);
    const divHeight = parseInt(this.props.nodePriorityHeight);
    //console.log("divTop: ", divTop);
    return (
      <>
        <button
          style={{
            backgroundColor: "#f7f1f1",
            borderWidth: "2px",
            borderColor: "orange",
            opacity: "1.0",
            height: "30px",
            width: "105px",
            borderRadius: "5px",
            transform: "translate(185px, -40px)",
            fontSize: "10px",
            fontWeight: "bold",
            color: "#777575",
          }}
          onClick={this.handleShow}
        >
          <p
            style={{
              width: "125px",
              fontSize: "10px",
              fontWeight: "bold",
              color: "#777575",
              textAlign: "center",
              height: "35px",
              display: "table-cell",
              verticalAlign: "middle",
              paddingBottom: "12px",
              //paddingLeft: "110px",
            }}
          >
            Power Information
          </p>
        </button>

        <Modal
          style={{
            width: "350px",
            height: "100%",
            alignContent: "center",
          }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          {/*<Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              System Essentials:
            </Modal.Title>
          </Modal.Header>*/}

          <Modal.Body style={{ height: "500px" }}>
            <EnergyIndepProg
              gridPowerProgress={gridPowerProgress}
              solarPowerProgress={solarPowerProgress}
              battPowerProgress={battPowerProgress}
            />
            <GaugeGraphII
              chartStartAngle={225}
              chartEndAngle={0}
              demandLimit={demandLimit}
              smMeter1={smMeter1}
              smMeter2={smMeter2}
              batteryPower={batteryPower}
            />

            <NodeProgBars
              realTimeNumber={realTimeNumber}
              realTimeColor="grey"
              intervalNumber={intervalNumber}
              intervalColor="grey"
              rollingIntervalNumber={rollingIntervalNumber}
              rollingIntervalColor="grey"
              powerUnit="kW"
              demandLimitPercent="90%"
              demandLimit={demandLimit}
            />
            <div
              className="progress-bar"
              style={{
                position: "absolute",
                top: "300px",
                left: "320px",
                height: "120px",
                backgroundColor: "grey",
                width: "15px",
                borderRadius: "3px",
              }}
            ></div>
            <PriorityProgress divTop={divTop} divHeight={divHeight} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default PowerModal;
