import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getSystems } from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackGroundRectangle from "../components/backgroundRect";
import {
  masterCompany,
  footerLogoSource,
  manufacturingCompany,
} from "../config.json";

class Manufactures extends Component {
  state = {
    systems: [],
  };

  async componentDidMount() {
    const { data: systems } = await getSystems();
    //systems = this.state.systems.data;
    console.log("Systems in the database", systems.data);
    console.log("Systems Length: ", systems.data.length);
    let maxSN = 0;
    let nextSN = 0;
    let nextFullSN = "";
    for (let i = 0; i < systems.data.length; i++) {
      const unitSN = systems.data[i].systemSN;
      const snPrefix = unitSN.slice(0, 6);
      //console.log("SN Prefix ", snPrefix);
      const SN = parseInt(unitSN.slice(7));
      //console.log("unit SN: ", SN);

      if (
        snPrefix === "simpl-" &&
        SN > maxSN &&
        SN !== 11149 &&
        SN !== 2502 &&
        SN !== 2501 &&
        SN !== 2500
      )
        maxSN = SN;
    }
    if (maxSN === 2499) maxSN = 2502;
    if (maxSN === 11148) maxSN = 11149;
    //console.log("Highest SN:", maxSN);
    nextSN = maxSN + 1;
    //console.log("Next SN:", nextSN);
    let nextSNString = nextSN.toString();
    //console.log("Next SN in string:", nextSNString);
    while (nextSNString.length < 6) nextSNString = "0" + nextSNString;
    nextFullSN = "simpl-" + nextSNString;
    console.log("Next Full SN: ", nextFullSN);
    this.setState({ systems, nextFullSN });
    console.log("Manufacturing Company: ", { manufacturingCompany });
  }

  render() {
    const systemCount = this.state.systems.length;
    if (systemCount === 0)
      return (
        <React.Fragment>
          <div
            style={{
              width: "350",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
            }}
          >
            <p> There are no manufactured master units in the database.</p>
            <Link
              to="/manufactures/new"
              className="btn btn-outline-info btn-sm"
              style={{ marginBottom: 20 }}
            >
              New Unit
            </Link>
          </div>{" "}
          <BackGroundRectangle />
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <Link
            to="/manufactures/newBoard"
            className="btn btn-outline-info btn-sm"
            style={{ width: 250, marginBottom: 20 }}
          >
            Manufacture a New Board
          </Link>
          <Link
            to="/manufactures/editBoard"
            className="btn btn-outline-primary btn-sm"
            style={{ width: 250, marginBottom: 20 }}
          >
            Edit a Board's Parameters
          </Link>
          {/*<Link
            to="/manufactures/newProduct"
            className="btn btn-outline-warning btn-sm"
            style={{ width: 250, marginBottom: 20 }}
          >
            Manufacture a New Product
        </Link>*/}
          <Link
            to={`/Companies/systems/${manufacturingCompany}`}
            className="btn btn-outline-success btn-sm"
            style={{ width: 250, marginBottom: 20 }}
          >
            Go to System View
          </Link>

          <button
            className="btn btn-outline-light"
            style={{
              borderWidth: "0px",
              transform: "translate(50px, -110px)",
            }}
          >
            <Link to="/manufactures/search">
              <FontAwesomeIcon
                icon="search"
                size="lg"
                color="dodgerblue"
                title="System Search"
              />
            </Link>
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default Manufactures;
