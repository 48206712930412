import React from "react";
import Form from "./common/form";
import {
  getSystemIDfromHandle,
  getSystem,
  setSystemAlive,
} from "../services/systemService";
import { masterCompany } from "../config.json";
import BackGroundRectangle from "../components/backgroundRect";
import { toast } from "react-toastify";

class SystemAlive extends Form {
  state = {
    system: {},
    handleId: "",
    systemId: "",
    systemSN: "",
    streetAddress: "",
    cityAddress: "",
    stateAddress: "",

    errors: {},
  };

  async componentDidMount() {
    const handleId = this.props.match.params.systemHandleID;
    const systemId = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const system = (await getSystem(systemId)).data.data[0];
    console.log("System Info", system);
    const systemSN = system.systemID.SN;
    const systemStatus = system.systemStatus;
    console.log("Serial Number", systemSN);
    this.setState({ systemSN, systemId, systemStatus, system });
  }

  handleSetAlive = async (e) => {
    e.preventDefault();
    console.log("System ID", this.state.systemId);
    if (this.state.systemStatus === "SUSPENDED")
      toast.warning(
        "This system was suspended!  This operation is invalid at this time!"
      );
    else {
      await setSystemAlive(this.props.match.params.systemHandleID);
      this.props.history.goBack();
    }
    /* this.props.history.push(
      `/Companies/systems/${this.props.match.params.companyName}`
    );*/
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            You are setting this system to ALIVE state:
          </p>
          <p className="basicParagraph">Please confirm:</p>
          <p className="basicParagraph" style={{ color: "red" }}>
            Caution: This operation could ONLY be performed by an Admin at{" "}
            {masterCompany}.
          </p>
          <button
            className="btn btn-danger btn-sm"
            onClick={this.handleSetAlive}
          >
            Set To ALIVE
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SystemAlive;
