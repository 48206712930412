import "intersection-observer";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemIntervalData,
  getSystemInfo,
  getSystemBasics,
  getSystemIDfromHandle,
  getSystem,
} from "../services/systemService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import { toast } from "react-toastify";
import AggregateGraph from "./aggregate-graph";
import { isMobile } from "react-device-detect";
import EnergyIndepProg from "./energyIndepProg";
import SystemIcons from "./systemIcons";
import EnergyAnimation from "./energyAnimation";
import SimplFooter from "./simplFooter";
import SimplVuNumbers from "./simplVuNumbers";
import SimplSOC from "./simplSOC";
import MainMenu from "./mainMenu";
import CostNSavingsModal from "./costSavings";
import SimpleMenu from "./simplDropDownMenu";
import SystemInfoModal from "./systemInfoModal";
import { animateScroll as scroll } from "react-scroll";
import { ScrollView } from "@cantonjs/react-scroll-view";

class SimplVu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [],
      dateNow: new Date(),

      endDate: new Date().getTime(),
      startDate: new Date().getTime() - 10000000,
      modeBox: "Real-time",
      anchorEl: null,
      companyName: "",
      key: null,
      systemID: "",
      handleID: 0,
      pvPowerUnits: "Watts",
      batteryPowerUnits: "Watts",
      intervalPeriod: 900,
      dataCount: 0,
      pvIntervalTotal: 0,
      battIntervalTotal: 0,
      pvInterval: [],
      response: {
        deviceLedger: {
          realTime: {
            timestamp: 0,
            pvPower: 0,
            batteryPower: 0,
          },
          Interval: {
            pvEnergy: 0,
            batteryEnergy: 0,
          },
        },
      },
    };
    this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    let deskTopView = "deskTop";
    if (isMobile) deskTopView = "notDeskTop";
    const toShowOrNot = localStorage.getItem("showNumbers");
    let showNumbers = false;
    if (toShowOrNot === "show") showNumbers = true;
    else showNumbers = false;

    const userRole = this.props.match.params.userRole;
    console.log("User Role", userRole);
    this.timerID = setInterval(() => this.tick(), 1000);
    const handleID = this.props.match.params.systemHandleID;
    const companyName = this.props.match.params.companyName;
    const systemInfo = await getSystemInfo(handleID);
    console.log("System Info in Dashboard CDM", systemInfo);
    const attachedSimplModuleSN = systemInfo.data.data.controls.moduleSN;
    const attachedSimplModuleHandle =
      systemInfo.data.data.controls.moduleHandleId;
    console.log("Module Handle ID:", attachedSimplModuleHandle);

    const moduleDataValid = systemInfo.data.data.controls.AttachedModuleValid;

    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    console.log("System ID:", systemID);
    const systemData = (await getSystem(systemID)).data.data[0].systemID;
    const serialNumber = systemData.SN;
    const macAddress = systemData.MAC;
    const systemName = systemData.name;
    this.setState({ serialNumber, macAddress, systemName });

    console.log("Serial Number", serialNumber);
    console.log("MAC Address:", macAddress);
    console.log("System Name", systemName);

    const systemStatus = systemInfo.data.data.status;
    console.log("system status", systemStatus);

    const backUpMode = parseInt(systemInfo.data.data.controls.backUpMode);
    console.log("BackUp Mode:", backUpMode);
    let acCoupledBackUp = 0;
    if (backUpMode === 3 || backUpMode === 4) acCoupledBackUp = 1;

    const systemBasics = systemInfo.data.data.basics;
    console.log("System Basics", systemBasics);
    const systemDescription = systemBasics.systemDescription;
    const systemConfiguration = systemBasics.systemConfiguration;
    const productSN = systemBasics.productSN;
    const emaSN = systemBasics.fourGserialNumber;
    const systemProduct = systemBasics.systemProduct;
    const systemType = systemBasics.systemType;
    console.log("System Type:", systemType);
    const paramUpdateRate = systemBasics.paramUpdateRate;
    const dataVerbosity = systemBasics.dataVerbosity;
    const dataFrequency = systemBasics.dataFrequency;

    let measPoint1 = "";
    let measPoint2 = "";
    if (systemConfiguration === "1") {
      const circuitName = systemBasics.circuitNameMeter1;
      if (circuitName === "0") measPoint1 = "Line/Grid";
      else if (circuitName === "1") measPoint1 = "Load/Building";
      else if (circuitName === "2") measPoint1 = "Solar";
      else measPoint1 = "Line/Grid";
      this.setState({ measPoint1 });
    } else if (
      systemConfiguration === "2" ||
      systemConfiguration === "3" ||
      systemConfiguration === "6" ||
      systemConfiguration === "7"
    ) {
      let circuitName = systemBasics.circuitNameMeter1;
      if (circuitName === "0") measPoint1 = "Line/Grid";
      else if (circuitName === "1") measPoint1 = "Load/Building";
      else if (circuitName === "2") measPoint1 = "Solar";
      else measPoint1 = "Line/Grid";
      this.setState({ measPoint1 });
      circuitName = systemBasics.circuitNameMeter2;
      if (circuitName === "0") measPoint2 = "Line/Grid";
      else if (circuitName === "1") measPoint2 = "Load/Building";
      else if (circuitName === "2") measPoint2 = "Solar";
      else measPoint2 = "Line/Grid";
      this.setState({ measPoint1, measPoint2 });
    }
    console.log("Measurement Point for Acuvim 1", this.state.measPoint1);
    console.log("Measurement Point for Acuvim 2", this.state.measPoint2);

    localStorage.setItem("systemMapButton", "noButton");

    this.setState({
      userRole,
      handleID,
      companyName,
      systemStatus,
      systemDescription,
      systemConfiguration,
      systemProduct,
      systemType,
      paramUpdateRate,
      dataVerbosity,
      dataFrequency,
      productSN,
      emaSN,
      attachedSimplModuleSN,
      attachedSimplModuleHandle,
      moduleDataValid,
      acCoupledBackUp,
      showNumbers,
      playPause: true,
      deskTopView,
    });
    this.setState({ firstLoading: true });
    const endDate = new Date().getTime();
    const startDate = endDate - 10000000;
    this.computeInterval(startDate, endDate);
    scroll.scrollToTop();
  }

  async computeInterval(startDate, endDate) {
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    console.log("handleID", this.props.match.params.systemHandleID);
    const systemIntervalDataInterim = await getSystemIntervalData(
      this.props.match.params.systemHandleID,
      startDate,
      endDate
    );
    const systemIntervalData = systemIntervalDataInterim.data.data;
    console.log("System Interval Data Interim", systemIntervalDataInterim);
    console.log("System Inteval Data", systemIntervalData);
    const recordLength = systemIntervalData.length;
    console.log("Record Length", recordLength);
    let intervalPeriod = 0;

    let pvIntervalTotal = 0;
    let battIntervalTotal = 0;
    let gridIntervalTotal = 0;
    let bldgIntervalTotal = 0;

    let pvEnergyValue = 0;
    let pvDemandValue = 0;
    let battEnergyValue = 0;
    let battDemandValue = 0;
    let gridEnergyValue = 0;
    let gridDemandValue = 0;
    let bldgEnergyValue = 0;
    let bldgDemandValue = 0;
    let bldgDemandValueNew = 0;
    let savingsIntervalTotal = 0;
    let savingsEnergyValue = 0;
    let bldgMaxDemand = 0;
    let gridMaxDemand = 0;
    let bldgMaxKW = 0;
    let gridMaxKW = 0;

    let demandRate = 0;
    let gridDemand = 0;
    let bldgDemand = 0;

    let pvInterval = [0];
    let date = [0];
    let dataCount = 0;
    let inCompleteData = 0;

    if (recordLength <= 0) intervalPeriod = 900;
    else {
      intervalPeriod =
        systemIntervalData[recordLength - 1].data.intervalDataFreq;
      console.log("Interval Raw Period", intervalPeriod);
      if (intervalPeriod === "oneMinute") intervalPeriod = 60;
      if (intervalPeriod === "fiveMinute") intervalPeriod = 300;
      if (intervalPeriod === "fifteenMinute") intervalPeriod = 900;
      if (intervalPeriod === "thirtyMinute") intervalPeriod = 1800;
      if (intervalPeriod === "sixtyMinute") intervalPeriod = 3600;
      console.log("Interval Calculated", intervalPeriod);
      console.log("Record Length", recordLength);
      console.log("Slave Count in Compute Interval", this.state.slaveCount);

      let i = 0;
      for (i = 0; i < recordLength; i++) {
        if (
          systemIntervalData[i].data.intervalDataFreq ===
          systemIntervalData[recordLength - 1].data.intervalDataFreq
        ) {
          if (
            this.state.systemConfiguration === "0" /*&&
            this.state.slaveCount > 0*/
          ) {
            //no acuvim ...only slave units
            if (
              !systemIntervalData[i].data.pvInterval ||
              !systemIntervalData[i].data.batteryInterval
            ) {
              inCompleteData = 1;
              pvIntervalTotal += 0;
              battIntervalTotal += 0;
              pvEnergyValue += 0;
              pvDemandValue = 0;
              battEnergyValue += 0;
              battDemandValue = 0;
            } else {
              pvIntervalTotal +=
                systemIntervalData[i].data.pvInterval / (100 * 3600 * 1000);
              battIntervalTotal +=
                systemIntervalData[i].data.batteryInterval /
                (100 * 3600 * 1000);
              pvEnergyValue += systemIntervalData[i].data.pvEnergyValue / 100;
              pvDemandValue = systemIntervalData[i].data.pvDemandValue / 100;
              battEnergyValue +=
                systemIntervalData[i].data.batteryEnergyValue / 100;
              battDemandValue =
                systemIntervalData[i].data.batteryDemandValue / 100;
            }
          } else if (
            this.state.systemConfiguration === "2" &&
            this.state.slaveCount < 1
          ) {
            //Two-ch acuvim and no slaves
            //convert to 15-min energy by dividing by 4
            demandRate = systemIntervalData[i].data.DemandRate;

            if (this.state.circuitNameMeter1 === "0") {
              gridDemand =
                (systemIntervalData[i].data.acuvimInterval_0 * demandRate) /
                1000;
              bldgDemand =
                ((systemIntervalData[i].data.acuvimInterval_0 +
                  systemIntervalData[i].data.acuvimInterval_1) *
                  demandRate) /
                1000;
            } else if (this.state.circuitNameMeter1 === "1") {
              bldgDemand =
                (systemIntervalData[i].data.acuvimInterval_0 * demandRate) /
                1000;
              gridDemand =
                ((systemIntervalData[i].data.acuvimInterval_0 -
                  systemIntervalData[i].data.acuvimInterval_1) *
                  demandRate) /
                1000;
            } else {
              gridDemand = 0.0;
              bldgDemand = 0.0;
            }

            if (gridDemand > gridMaxDemand) {
              gridMaxDemand = gridDemand;
              gridMaxKW = gridDemand / demandRate;
            }
            if (bldgDemand > bldgMaxDemand) {
              bldgMaxDemand = bldgDemand;
              bldgMaxKW = bldgDemand / demandRate;
            }

            pvIntervalTotal +=
              systemIntervalData[i].data.acuvimInterval_1 / (4 * 1000);
            pvDemandValue = systemIntervalData[i].data.acuvimDemandValue_1;
            pvEnergyValue += systemIntervalData[i].data.acuvimEnergyValue_1;

            if (this.state.circuitNameMeter1 === "0") {
              gridIntervalTotal +=
                systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
              gridDemandValue = systemIntervalData[i].data.acuvimDemandValue_0;
              gridEnergyValue += systemIntervalData[i].data.acuvimEnergyValue_0;

              bldgIntervalTotal +=
                (systemIntervalData[i].data.acuvimInterval_0 +
                  systemIntervalData[i].data.acuvimInterval_1) /
                (4 * 1000);

              bldgDemandValueNew =
                systemIntervalData[i].data.acuvimDemandValue_0 +
                systemIntervalData[i].data.acuvimDemandValue_1;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;

              bldgEnergyValue +=
                systemIntervalData[i].data.acuvimEnergyValue_0 +
                systemIntervalData[i].data.acuvimEnergyValue_1;
            } else if (this.state.circuitNameMeter1 === "1") {
              bldgIntervalTotal +=
                systemIntervalData[i].data.acuvimInterval_0 / (4 * 1000);
              bldgDemandValueNew =
                systemIntervalData[i].data.acuvimDemandValue_0;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;
              bldgEnergyValue += systemIntervalData[i].data.acuvimEnergyValue_0;

              gridIntervalTotal +=
                (systemIntervalData[i].data.acuvimInterval_0 -
                  systemIntervalData[i].data.acuvimInterval_1) /
                (4 * 1000);
              gridDemandValue =
                systemIntervalData[i].data.acuvimDemandValue_0 -
                systemIntervalData[i].data.acuvimDemandValue_1;
              gridEnergyValue +=
                systemIntervalData[i].data.acuvimEnergyValue_0 -
                systemIntervalData[i].data.acuvimEnergyValue_1;
            } else {
              gridIntervalTotal = 0.0;
              bldgIntervalTotal = 0.0;
            }
          }

          //////////////////////////////////////////////////////////////////////////////////////
          else if (
            (this.state.systemConfiguration === "3" ||
              this.state.systemConfiguration === "7") &&
            this.state.slaveCount < 1
          ) {
            //Two-ch ATM power meters used in SimplModule SimplMeter II
            //convert to 15-min energy by dividing by 4
            demandRate = systemIntervalData[i].data.DemandRate; //$/kW

            if (this.state.circuitNameMeter1 === "0") {
              //All Demand information is in units of kW
              gridDemand = systemIntervalData[i].data.Interval_0 * demandRate; //kW * $/kW
              bldgDemand =
                (systemIntervalData[i].data.Interval_0 +
                  systemIntervalData[i].data.Interval_1) *
                demandRate;
            } else if (this.state.circuitNameMeter1 === "1") {
              bldgDemand = systemIntervalData[i].data.Interval_0 * demandRate;
              gridDemand =
                (systemIntervalData[i].data.Interval_0 -
                  systemIntervalData[i].data.Interval_1) *
                demandRate;
            } else {
              gridDemand = 0.0;
              bldgDemand = 0.0;
            }

            if (gridDemand > gridMaxDemand) {
              gridMaxDemand = gridDemand;
              gridMaxKW = gridDemand / demandRate;
            }
            if (bldgDemand > bldgMaxDemand) {
              bldgMaxDemand = bldgDemand;
              bldgMaxKW = bldgDemand / demandRate;
            }
            //All Energy is in units of kWh
            pvIntervalTotal += systemIntervalData[i].data.Interval_1 / 4;
            pvDemandValue = systemIntervalData[i].data.DemandValue_1;
            pvEnergyValue += systemIntervalData[i].data.EnergyValue_1;

            if (this.state.circuitNameMeter1 === "0") {
              gridIntervalTotal += systemIntervalData[i].data.Interval_0 / 4;
              gridDemandValue = systemIntervalData[i].data.DemandValue_0;
              gridEnergyValue += systemIntervalData[i].data.EnergyValue_0;

              bldgIntervalTotal +=
                (systemIntervalData[i].data.Interval_0 +
                  systemIntervalData[i].data.Interval_1) /
                4;

              bldgDemandValueNew =
                systemIntervalData[i].data.DemandValue_0 +
                systemIntervalData[i].data.DemandValue_1;

              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;

              bldgEnergyValue +=
                systemIntervalData[i].data.EnergyValue_0 +
                systemIntervalData[i].data.EnergyValue_1;
            } else if (this.state.circuitNameMeter1 === "1") {
              bldgIntervalTotal += systemIntervalData[i].data.Interval_0 / 4;
              bldgDemandValueNew = systemIntervalData[i].data.DemandValue_0;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;
              bldgEnergyValue += systemIntervalData[i].data.EnergyValue_0;

              gridIntervalTotal +=
                (systemIntervalData[i].data.Interval_0 -
                  systemIntervalData[i].data.Interval_1) /
                4;
              gridDemandValue =
                systemIntervalData[i].data.DemandValue_0 -
                systemIntervalData[i].data.DemandValue_1;
              gridEnergyValue +=
                systemIntervalData[i].data.EnergyValue_0 -
                systemIntervalData[i].data.EnergyValue_1;
            } else {
              gridIntervalTotal = 0.0;
              bldgIntervalTotal = 0.0;
            }
          }
          ////////////////////////////////////////////////////////////////////////////////////
          else if (this.state.systemConfiguration === "6") {
            //Tw0-channel SimplMeter master/slave configuration
            //convert to 15-min energy by dividing by 4
            demandRate = systemIntervalData[i].data.DemandRate; //$/kW

            if (this.state.circuitNameMeter1 === "0") {
              //All Demand information is in units of kW
              gridDemand =
                systemIntervalData[i].data.meter1Interval * demandRate; //kW * $/kW
              bldgDemand =
                (systemIntervalData[i].data.meter1Interval +
                  systemIntervalData[i].data.meter2Interval) *
                demandRate;
            } else if (this.state.circuitNameMeter1 === "1") {
              bldgDemand =
                systemIntervalData[i].data.meter1Interval * demandRate;
              gridDemand =
                (systemIntervalData[i].data.meter1Interval -
                  systemIntervalData[i].data.meter2Interval) *
                demandRate;
            } else {
              gridDemand = 0.0;
              bldgDemand = 0.0;
            }

            if (gridDemand > gridMaxDemand) {
              gridMaxDemand = gridDemand;
              gridMaxKW = gridDemand / demandRate;
            }
            if (bldgDemand > bldgMaxDemand) {
              bldgMaxDemand = bldgDemand;
              bldgMaxKW = bldgDemand / demandRate;
            }
            //All Energy is in units of kWh
            pvIntervalTotal += systemIntervalData[i].data.meter2Interval / 4;
            pvDemandValue = systemIntervalData[i].data.meter2DemandValue;
            pvEnergyValue += systemIntervalData[i].data.meter2EnergyValue;

            if (this.state.circuitNameMeter1 === "0") {
              gridIntervalTotal +=
                systemIntervalData[i].data.meter1Interval / 4;
              gridDemandValue = systemIntervalData[i].data.meter1DemandValue;
              gridEnergyValue += systemIntervalData[i].data.meter1EnergyValue;

              bldgIntervalTotal +=
                (systemIntervalData[i].data.meter1Interval +
                  systemIntervalData[i].data.meter2Interval) /
                4;

              bldgDemandValueNew =
                systemIntervalData[i].data.meter1DemandValue +
                systemIntervalData[i].data.meter2DemandValue;

              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;

              bldgEnergyValue +=
                systemIntervalData[i].data.meter1EnergyValue +
                systemIntervalData[i].data.meter2EnergyValue;
            } else if (this.state.circuitNameMeter1 === "1") {
              bldgIntervalTotal +=
                systemIntervalData[i].data.meter1Interval / 4;
              bldgDemandValueNew = systemIntervalData[i].data.meter1DemandValue;
              if (bldgDemandValueNew > bldgDemandValue)
                bldgDemandValue = bldgDemandValueNew;
              bldgEnergyValue += systemIntervalData[i].data.meter1EnergyValue;

              gridIntervalTotal +=
                (systemIntervalData[i].data.meter1Interval -
                  systemIntervalData[i].data.meter2Interval) /
                4;
              gridDemandValue =
                systemIntervalData[i].data.meter1DemandValue -
                systemIntervalData[i].data.meter2DemandValue;
              gridEnergyValue +=
                systemIntervalData[i].data.meter1EnergyValue -
                systemIntervalData[i].data.meter2EnergyValue;
            } else {
              gridIntervalTotal = 0.0;
              bldgIntervalTotal = 0.0;
            }
          }
          //////////////////////////////////////////////////////////////////////////////////////////

          dataCount++;
        }
      }
    }
    savingsIntervalTotal = pvIntervalTotal + battIntervalTotal;
    savingsEnergyValue = pvEnergyValue + battEnergyValue;

    let demandSavings = bldgMaxDemand - gridMaxDemand;
    let totalSavings = demandSavings + savingsEnergyValue;

    let totalUsage = bldgMaxDemand + bldgEnergyValue;
    let netCosts = totalUsage - totalSavings;
    let percentEnergySavings = (
      (savingsEnergyValue / bldgEnergyValue) *
      100
    ).toFixed(0);
    let percentDemandSavings = ((demandSavings / bldgMaxDemand) * 100).toFixed(
      0
    );
    let percentTotalSavings = ((totalSavings / totalUsage) * 100).toFixed(0);

    gridMaxDemand = gridMaxDemand
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgMaxDemand = bldgMaxDemand
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    demandSavings = demandSavings
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    totalSavings = totalSavings
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    gridMaxKW = gridMaxKW
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgMaxKW = bldgMaxKW
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    totalUsage = totalUsage
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    netCosts = netCosts
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.sigFigures(gridIntervalTotal);
    gridIntervalTotal = this.state.sigNumber;
    this.sigFigures(pvIntervalTotal);
    pvIntervalTotal = this.state.sigNumber;
    this.sigFigures(bldgIntervalTotal);
    bldgIntervalTotal = this.state.sigNumber;
    this.sigFigures(battIntervalTotal);
    battIntervalTotal = this.state.sigNumber;
    this.sigFigures(savingsIntervalTotal);
    savingsIntervalTotal = this.state.sigNumber;

    this.sigFigures(gridDemandValue);
    gridDemandValue = this.state.sigNumber;
    this.sigFigures(pvDemandValue);
    pvDemandValue = this.state.sigNumber;
    this.sigFigures(bldgDemandValue);
    bldgDemandValue = this.state.sigNumber;
    this.sigFigures(battDemandValue);
    battDemandValue = this.state.sigNumber;

    //this.sigFigures(gridEnergyValue);
    //gridEnergyValue = this.state.sigNumber;
    //this.sigFigures(pvEnergyValue);
    //pvEnergyValue = this.state.sigNumber;
    //this.sigFigures(bldgEnergyValue);
    // bldgEnergyValue = this.state.sigNumber;
    this.sigFigures(battEnergyValue);
    battEnergyValue = this.state.sigNumber;
    //this.sigFigures(savingsEnergyValue);
    // savingsEnergyValue = this.state.sigNumber;

    bldgEnergyValue = bldgEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    pvEnergyValue = pvEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    savingsEnergyValue = savingsEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    gridEnergyValue = gridEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.setState({
      inCompleteData,

      intervalPeriod,
      pvInterval,

      pvIntervalTotal,
      pvEnergyValue,
      pvDemandValue,

      battIntervalTotal,
      battEnergyValue,
      battDemandValue,

      gridIntervalTotal,
      gridEnergyValue,
      gridDemandValue,

      bldgIntervalTotal,
      bldgEnergyValue,
      bldgDemandValue,

      savingsIntervalTotal,
      savingsEnergyValue,

      gridMaxDemand,
      bldgMaxDemand,
      demandSavings,
      totalSavings,

      gridMaxKW,
      bldgMaxKW,

      totalUsage,
      netCosts,

      percentEnergySavings,
      percentDemandSavings,
      percentTotalSavings,

      dataCount,
      date,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};
    console.log(
      "dasjboard.js: getSystemData()  Got to here with webSocketMode :",
      webSocketMode
    );
    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      const response_temp = await getSystemLatestData(this.state.handleID);
      console.log("Response Full Info", response_temp);
      response = response_temp.data.data[0];
      console.log("Http: Latest Data", response);
    }
    if (response !== undefined && response !== null) {
      const ipAddress = response.data.deviceLedger.ipString;
      localStorage.setItem("ipAddress", ipAddress);
      const systemInfo = await getSystemInfo(this.state.handleID);
      const systemStatus = systemInfo.data.data.status;

      //if (response !== undefined && response !== null) {
      const commMode = response.data.commMode;
      console.log("Comm Mode", commMode);
      const hwConfig = response.data.hwConfig;
      console.log("Comm Mode", hwConfig);
      const moduleDataValid = response.data.AttachedModuleValid;

      let smMeter1 = 0;
      let smMeter2 = 0;
      // let responseAttachedModule = 0;
      /////////////////////////////////////////////////////////////////////////
      if (moduleDataValid === 1 && this.state.attachedSimplModuleHandle !== 0) {
        const responseAttachedModule = await getSystemLatestData(
          this.state.attachedSimplModuleHandle
        );
        console.log("Attached Module Response:", responseAttachedModule);
        const attachedModuleData = responseAttachedModule.data.data[0];
        console.log("Attached Module Data:", attachedModuleData);
        if (attachedModuleData !== undefined) {
          smMeter1 =
            responseAttachedModule.data.data[0].data.deviceLedger.simplMeter_ii
              .sm2Ptotal1;
          smMeter2 =
            responseAttachedModule.data.data[0].data.deviceLedger.simplMeter_ii
              .sm2Ptotal2;
          console.log("Meter 1: ", smMeter1);
          console.log("Meter 2: ", smMeter2);
        }
      } else {
        // toast.error("No SimplMeter Module is Attached...");
      }

      ///////////////////////////////////////////////////////////////////////////////
      const commProvider = response.data.commProvider;
      console.log("Comm Provider", commProvider);
      const MeshId = response.data.MeshId;
      console.log("Mesh ID", MeshId);
      const wifiSSID = response.data.deviceLedger.SSID;
      let loadOne = false;
      let loadTwo = false;
      let loadThree = false;
      if (this.state.systemConfiguration === "5") {
        const loadsStatus = response.data.loadsStatus;

        if ((loadsStatus & 0x01) !== 0) loadOne = true;
        if ((loadsStatus & 0x02) !== 0) loadTwo = true;
        if ((loadsStatus & 0x04) !== 0) loadThree = true;
      }

      console.log("Load One", loadOne);
      console.log("Load Two", loadTwo);
      console.log("Load Three", loadThree);

      const realtimeEnergyRate = response.data.deviceLedger.realTime.energyRate;
      console.log("Real Time Energy Rate:", realtimeEnergyRate);

      const outageFlag = parseInt(
        response.data.deviceLedger.realTime.outageFlag
      );
      const outageCount =
        parseInt(response.data.deviceLedger.realTime.outageCount) * 10; //Count increases once every 10 second in the timer task in the master unit

      const systemTimeZone = parseInt(
        response.data.deviceLedger.realTime.TimeZone
      );
      const timeStampLatestData = parseInt(response.timestamp / 1000);
      console.log("Latest Data's Time Stamp", timeStampLatestData);
      const epochLocalTime = Math.round(new Date().getTime() / 1000);
      console.log("Epoch Time", epochLocalTime);
      const systemTime = parseInt(
        response.data.deviceLedger.realTime.SystemLocalTimeSeconds
      );
      console.log("System Time:", systemTime);

      //Deal with Old Data when reading info from latestData route
      const timeDelta = Math.abs(timeStampLatestData - epochLocalTime);
      let dataTimeInvalid = false;
      //calculate maxDelta = dataFrequency + 10 seconds
      //If wifi dataFrequncy = whatever is set in systemBasics
      //If 4G dataFrequency = dataFrequency from data plan
      const systemBasics = await getSystemBasics(this.state.handleID);
      console.log("System Basics", systemBasics);
      const circuitNameMeter1 = systemBasics.data.data.basics.circuitNameMeter1;
      console.log("Grid or Bldg: ", circuitNameMeter1);
      const dataFrequency = systemBasics.data.data.basics.dataFrequency;
      console.log("Data Frequency", dataFrequency);
      let dataFrequency_delta = 0;
      if (dataFrequency === "1") dataFrequency_delta = 1;
      //"1", name: "once / Second"
      else if (dataFrequency === "2") dataFrequency_delta = 5;
      //"2", name: "once / 5 Seconds"
      else if (dataFrequency === "3") dataFrequency_delta = 15;
      //"3", name: "once / 15 Seconds"
      else if (dataFrequency === "4") dataFrequency_delta = 60;
      //"4", name: "Once / Minute"
      else if (dataFrequency === "5") dataFrequency_delta = 300;
      //"5", name: "Once / 5 Minutes"
      else if (dataFrequency === "6") dataFrequency_delta = 900;
      //"6", name: "Once / 15 Minutes"
      else if (dataFrequency === "7") dataFrequency_delta = 1800;
      //"7", name: "Once / 30 Minutes"
      else if (dataFrequency === "8") dataFrequency_delta = 3600; //"8", name: "Once / Hour"

      if (commMode === 2 && dataFrequency_delta < 15) dataFrequency_delta = 15; //If 4G, should not go any faster than 15 seconds
      console.log("dataFreqency_delta", dataFrequency_delta);
      if (timeDelta > 15 + dataFrequency_delta) {
        dataTimeInvalid = true;
      } else dataTimeInvalid = false;
      console.log("Delta Time :", timeDelta);
      console.log("Data Time Invalid", dataTimeInvalid);

      const loopCount = response.data.deviceLedger.realTime.loopCount;
      const freeHeap = response.data.freeHeapBytes;
      const minHeap = response.data.minHeapBytes;
      console.log("Loop Count ", loopCount);

      const fwVersionMaster = response.data.deviceLedger.fwVersion;
      const fwAppName = response.data.deviceLedger.appName;
      //console.log("Master Firmware Version", fwVersionMaster);

      const wifiSignalStrength = response.data.deviceLedger.realTime.RSSI;
      //console.log("Wifi Signal Strength", wifiSignalStrength);

      const signalQuality = response.data.deviceLedger.realTime.sigQuality;
      // const signalStrength = -70.0;

      //const slaveCount = 0; /*response.data.deviceLedger.realTime.slaveCount*/
      const slaveCount = response.data.deviceLedger.realTime.slaveCount;

      console.log("System Time Zone:", systemTimeZone);
      let sysT = new Date((systemTime - systemTimeZone * 3600) * 1000);
      const systemTimeOffset = systemTimeZone * 60 * 60 * 1000;
      const userTimeOffset = sysT.getTimezoneOffset() * 60 * 1000;
      sysT = new Date(sysT.getTime() + userTimeOffset + systemTimeOffset);
      let hourTime = sysT.getHours();
      if (hourTime < 10) hourTime = "0" + hourTime;
      let minuteTime = sysT.getMinutes();
      if (minuteTime < 10) minuteTime = "0" + minuteTime;
      let secTime = sysT.getSeconds();
      if (secTime < 10) secTime = "0" + secTime;
      const systemHumanTime = [hourTime, minuteTime, secTime].join(":");
      console.log("System Human Time:", systemHumanTime);
      this.setState({ systemHumanTime });

      const endDate = new Date().getTime() - 10;
      const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
      const systemIntervalDataInterim = await getSystemIntervalData(
        this.props.match.params.systemHandleID,
        startDate,
        endDate
      );
      console.log("Interval Start Time: ", startDate);
      console.log("Interval End Date: ", endDate);
      console.log("Interval Data", systemIntervalDataInterim);
      const systemIntervalData = systemIntervalDataInterim.data.data;
      const recordLength = systemIntervalData.length;
      console.log("System Interval Data Interim", systemIntervalDataInterim);
      let energyRate = 0;
      let demandRate = 0;
      let onPeakEnergy = 0;
      let onPeakDemand = 0;
      if (recordLength >= 1) {
        energyRate = systemIntervalData[recordLength - 1].data.EnergyRate;
        demandRate =
          systemIntervalData[recordLength - 1].data.DemandRate.toFixed(2);
        onPeakEnergy = systemIntervalData[recordLength - 1].data.OnPeakEnergy;
        onPeakDemand = systemIntervalData[recordLength - 1].data.OnPeakDemand;
      }

      //console.log("Energy Rate", energyRate);
      this.setState({ energyRate, demandRate, onPeakEnergy, onPeakDemand });

      this.setState({
        realTime: response.data.deviceLedger.realTime,
      });
      this.setState({
        acuvimData: response.data.deviceLedger.acuvim,
      });
      /////////////////////////////////////////////////////////////////////
      if (
        this.state.systemConfiguration === "3" ||
        this.state.systemConfiguration === "7"
      )
        this.setState({
          simplMeter_iiData: response.data.deviceLedger.simplMeter_ii,
        });
      if (this.state.systemConfiguration === "6")
        this.setState({
          simplMeter_slave: response.data.deviceLedger.realTime,
        });
      /////////////////////////////////////////////////////////////////
      this.setState({
        Interval: response.data.deviceLedger.Interval,
      });

      let pvPower = 0;
      let pvPowerUnits = this.state.pvPowerUnits;
      let batteryPower = 0;
      let batteryPowerUnits = this.state.batteryPowerUnits;
      let gridPower = 0;
      let bldgPower = 0;
      let gridPowerUnits = this.state.gridPowerUnits;
      let bldgPowerUnits = this.state.bldgPowerUnits;
      let aSVU = 0;
      let bSVU = 0;
      let cSVU = 0;
      let dSVU = 0;
      let eSVU = 0;
      let pHouse = 0;
      if (
        this.state.systemConfiguration === "0" &&
        /*this.state.realTime.slaveCount > 0 &&*/
        moduleDataValid === 0
      ) {
        pvPower = this.state.realTime.pvPower / 100;
        batteryPower = this.state.realTime.batteryPower / 100;
        gridPower = (-1.0 * this.state.realTime.inverterPower) / 100.0;
        bldgPower = this.state.realTime.buildingPower / 100; //0.0;
      }
      /////////////////////////////////////////////////////////////////////////
      else if (
        this.state.systemConfiguration === "0" &&
        this.state.realTime.slaveCount > 0 &&
        moduleDataValid === 1
      ) {
        pvPower = this.state.realTime.pvPower / 100;
        batteryPower = this.state.realTime.batteryPower / 100;
        gridPower = (-1.0 * this.state.realTime.inverterPower) / 100.0;
        bldgPower = this.state.realTime.buildingPower / 100; //0.0;
        if (batteryPower > 0.0) {
          //Discharging
          if (smMeter1 > 0) {
            //buying
            aSVU = batteryPower;
            bSVU = smMeter2 - batteryPower;
            cSVU = smMeter1;
            dSVU = 0;
            eSVU = 0;
            pHouse = smMeter1 + smMeter2;
          } else {
            //selling
            aSVU = batteryPower + smMeter1; //was just batteryPower before: aSVU = batteryPower;
            bSVU = smMeter2 + smMeter1 - batteryPower;
            cSVU = 0;
            dSVU = 0;
            eSVU = -1.0 * smMeter1;
            pHouse = smMeter1 + smMeter2;
          }
        } else {
          //Charging

          if (smMeter1 > 0) {
            //buying
            aSVU = 0;
            bSVU = smMeter2;
            cSVU = smMeter1;
            dSVU = -1.0 * batteryPower;
            eSVU = 0;
            pHouse = smMeter1 + smMeter2;
          } else {
            //selling
            aSVU = 0;
            bSVU = smMeter1 + smMeter2;
            cSVU = 0.0;
            dSVU = -1.0 * batteryPower;
            eSVU = -1.0 * smMeter1;
            pHouse = smMeter1 + smMeter2;
          }
        }
      }
      /////////////////////////////////////////////////////////////////////////
      else if (
        this.state.systemConfiguration === "1" &&
        response.data.deviceLedger.acuvim !== undefined
      ) {
        gridPower = this.state.acuvimData.acuvimPower0;
        pvPower = 0.0;
        batteryPower = 0.0;
        bldgPower = 0.0;
      } else if (
        this.state.systemConfiguration === "2" &&
        response.data.deviceLedger.acuvim !== undefined
      ) {
        pvPower = this.state.acuvimData.acuvimPower1;
        if (circuitNameMeter1 === "0") {
          gridPower = this.state.acuvimData.acuvimPower0;
          bldgPower = gridPower + this.state.acuvimData.acuvimPower1;
        } else if (circuitNameMeter1 === "1") {
          bldgPower = this.state.acuvimData.acuvimPower0;
          gridPower = bldgPower - this.state.acuvimData.acuvimPower1;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
        batteryPower = 0.0;
        if (gridPower > 0) {
          //buying
          aSVU = 0;
          bSVU = pvPower;
          cSVU = gridPower;
          dSVU = 0.0;
          eSVU = 0;
          pHouse = gridPower + pvPower;
        } else {
          //selling
          aSVU = 0;
          bSVU = gridPower + pvPower;
          cSVU = 0.0;
          dSVU = 0.0;
          eSVU = -1.0 * gridPower;
          pHouse = gridPower + pvPower;
        }
      }

      ///////////////////////////////////////////////////////////////////////////
      else if (
        (this.state.systemConfiguration === "3" ||
          this.state.systemConfiguration === "7") &&
        response.data.deviceLedger.simplMeter_ii !== undefined
      ) {
        if (this.state.simplMeter_iiData.sm2Ptotal2 !== null)
          pvPower = this.state.simplMeter_iiData.sm2Ptotal2;
        else pvPower = 0;
        if (circuitNameMeter1 === "0") {
          gridPower = this.state.simplMeter_iiData.sm2Ptotal1;
          bldgPower = gridPower + this.state.simplMeter_iiData.sm2Ptotal2;
        } else if (circuitNameMeter1 === "1") {
          bldgPower = this.state.simplMeter_iiData.sm2Ptotal1;
          gridPower = bldgPower - this.state.simplMeter_iiData.sm2Ptotal2;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
        batteryPower = 0.0;

        if (gridPower > 0) {
          //buying
          aSVU = 0;
          bSVU = pvPower;
          cSVU = gridPower;
          dSVU = 0.0;
          eSVU = 0;
          pHouse = gridPower + pvPower;
        } else {
          //selling
          aSVU = 0;
          bSVU = gridPower + pvPower;
          cSVU = 0.0;
          dSVU = 0.0;
          eSVU = -1.0 * gridPower;
          pHouse = gridPower + pvPower;
        }
      } else if (
        this.state.systemConfiguration === "6" &&
        response.data.deviceLedger.slaveUnits !== undefined
      ) {
        if (this.state.simplMeter_slave.AggPower2 !== null)
          pvPower = this.state.simplMeter_slave.AggPower2;
        else pvPower = 0;
        if (circuitNameMeter1 === "0") {
          gridPower = this.state.simplMeter_slave.AggPower1;
          bldgPower = gridPower + this.state.simplMeter_slave.AggPower2;
        } else if (circuitNameMeter1 === "1") {
          bldgPower = this.state.simplMeter_slave.AggPower1;
          gridPower = bldgPower - this.state.simplMeter_slave.AggPower2;
        } else {
          gridPower = 0.0;
          bldgPower = 0.0;
        }
        batteryPower = 0.0;
        if (gridPower > 0) {
          //buying
          aSVU = 0;
          bSVU = pvPower;
          cSVU = gridPower;
          dSVU = 0.0;
          eSVU = 0;
          pHouse = gridPower + pvPower;
        } else {
          //selling
          aSVU = 0;
          bSVU = gridPower + pvPower;
          cSVU = 0.0;
          dSVU = 0.0;
          eSVU = -1.0 * gridPower;
          pHouse = gridPower + pvPower;
        }
      }
      /////////////////////////////////////////////////////////////////////////////
      let gridPowerProgress = 100;
      let solarPowerProgress = 0.0;
      let battPowerProgress = 0.0;
      let energyIndependence = 0.0;
      if (pHouse > 0.0) {
        gridPowerProgress = (cSVU / pHouse) * 100.0;
        solarPowerProgress = (bSVU / pHouse) * 100.0;
        battPowerProgress = (aSVU / pHouse) * 100.0;
        energyIndependence = (solarPowerProgress + battPowerProgress).toFixed(
          0
        );
        if (energyIndependence < 0.0001)
          energyIndependence = (0.0001).toFixed(0);
      }

      let overallSOC = 0.0;
      let rangeHours = 0.0;
      if (
        this.state.systemConfiguration === "0" &&
        this.state.realTime.slaveCount > 0 &&
        moduleDataValid === 1
      ) {
        overallSOC = response.data.deviceLedger.realTime.battSOC.toFixed(0);
        if (batteryPower > 0.0)
          rangeHours = (
            (this.state.realTime.slaveCount * (overallSOC / 100) * 1440) /
            batteryPower
          ).toFixed(1);
        else if (batteryPower <= 0.0 && pHouse !== 0.0)
          rangeHours = (
            (this.state.realTime.slaveCount * (overallSOC / 100) * 1440) /
            pHouse
          ).toFixed(1);
        else rangeHours = "--";
        if (rangeHours >= 99.0) rangeHours = 99.0;
      }

      let solarProgressBar = 0;
      let batteryProgressBar = 0;
      let gridProgressBar = 0;

      if (bldgPower > 0) {
        console.log("In here Bldg Power > 0");
        solarProgressBar = ((pvPower * 100) / bldgPower).toFixed(1);
        batteryProgressBar = ((batteryPower * 100) / bldgPower).toFixed(1);
        gridProgressBar = ((gridPower * 100) / bldgPower).toFixed(1);
      } else {
        gridProgressBar = 100.0;
        if (batteryPower > 0) {
          //if discharging
          solarProgressBar = (
            (parseFloat(pvPower) * 100) /
            (parseFloat(pvPower) + parseFloat(batteryPower))
          ).toFixed(1);
          batteryProgressBar = (
            (parseFloat(batteryPower) * 100) /
            (parseFloat(pvPower) + parseFloat(batteryPower))
          ).toFixed(1);
        } else {
          //if charging
          solarProgressBar = 100.0;
          batteryProgressBar = 0.0;
        }
      }
      /////////////////////////////////////////////
      this.setState({ solarProgressBar, batteryProgressBar, gridProgressBar });
      /////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////////////////////////
      let aSVUPowerUnits = "Watts";
      let bSVUPowerUnits = "Watts";
      let cSVUPowerUnits = "Watts";
      let dSVUPowerUnits = "Watts";
      let eSVUPowerUnits = "Watts";
      let pHousePowerUnits = "Watts";
      if (
        (this.state.systemConfiguration === "0" &&
          /*this.state.realTime.slaveCount > 0 &&*/
          moduleDataValid === 1) ||
        this.state.systemConfiguration === "2" ||
        this.state.systemConfiguration === "3" ||
        this.state.systemConfiguration === "6" ||
        this.state.systemConfiguration === "7"
      ) {
        this.powerNunits(aSVU);
        aSVU = this.state.power;
        //aSVU = 0.0;
        aSVUPowerUnits = this.state.powerUnits;
        this.setState({ aSVU, aSVUPowerUnits });

        this.powerNunits(bSVU);
        bSVU = this.state.power;
        //bSVU = 0.0;
        bSVUPowerUnits = this.state.powerUnits;
        this.setState({ bSVU, bSVUPowerUnits });

        this.powerNunits(cSVU);
        cSVU = this.state.power;
        //cSVU = 0.0;
        cSVUPowerUnits = this.state.powerUnits;
        this.setState({ cSVU, cSVUPowerUnits });

        this.powerNunits(dSVU);
        dSVU = this.state.power;
        //dSVU = 0.0;
        dSVUPowerUnits = this.state.powerUnits;
        this.setState({ dSVU, dSVUPowerUnits });

        this.powerNunits(eSVU);
        eSVU = this.state.power;
        //eSVU = 0.0;
        eSVUPowerUnits = this.state.powerUnits;
        this.setState({ eSVU, eSVUPowerUnits });

        this.powerNunits(pHouse);
        pHouse = this.state.power;
        //pHouse = 0.0;
        pHousePowerUnits = this.state.powerUnits;
        this.setState({ pHouse, pHousePowerUnits });
      }
      if (gridPower) {
        this.powerNunits(gridPower);
        gridPower = this.state.power;
        gridPowerUnits = this.state.powerUnits;
        this.setState({ gridPower, gridPowerUnits });
      }
      this.powerNunits(bldgPower);
      bldgPower = this.state.power;
      bldgPowerUnits = this.state.powerUnits;
      this.setState({ bldgPower, bldgPowerUnits });
      this.powerNunits(pvPower);
      pvPower = this.state.power;
      pvPowerUnits = this.state.powerUnits;
      this.setState({ pvPower, pvPowerUnits });
      this.powerNunits(batteryPower);
      batteryPower = this.state.power;
      //batteryPower = 0.0;
      batteryPowerUnits = this.state.powerUnits;
      this.setState({ batteryPower, batteryPowerUnits });
      /////////////////////////////////////

      let pvEnergy = 0;
      let batteryEnergy = 0;
      if (pvEnergy && batteryEnergy) {
        pvEnergy = this.state.Interval.pvEnergy / 100;
        batteryEnergy = this.state.Interval.batteryEnergy / 100;
      }

      this.setState({
        pvEnergy,
        batteryEnergy,
        gridPowerProgress,
        solarPowerProgress,
        battPowerProgress,
        energyIndependence,
      });

      this.setState({
        soc: (
          (this.state.realTime.batteryPower / this.state.realTime.pvPower) *
          100
        ).toFixed(1),
      });

      this.setState({ pvPower, batteryPower, gridPower, bldgPower });

      this.setState({
        loopCount,
        fwAppName,
        fwVersionMaster,
        systemStatus,
        commMode,
        commProvider,
        MeshId,
        moduleDataValid,
        wifiSignalStrength,
        signalQuality,
        wifiSSID,
        slaveCount,
        dataTimeInvalid,
        loadOne,
        loadTwo,
        loadThree,
        circuitNameMeter1,
        outageFlag,
        outageCount,
        overallSOC,
        rangeHours,
        hwConfig,
        freeHeap,
        minHeap,
      });
      this.setState({
        aSVU,
        bSVU,
        cSVU,
        dSVU,
        eSVU,
        pHouse,
        smMeter1,
        smMeter2,
      });
      //const tempSlaveCount = 0;
      //this.setState({ slaveCount: tempSlaveCount });
      this.setState({ slaveCount: this.state.realTime.slaveCount });
      this.setState({ firstLoading: false });
    }
  }

  sigFigures(powerDollar) {
    let sigNumber = 0;
    if (powerDollar < 100) sigNumber = powerDollar.toFixed(2);
    else if (powerDollar < 1000) sigNumber = powerDollar.toFixed(1);
    else
      sigNumber = powerDollar
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ sigNumber });
  }

  powerNunits(power) {
    let powerUnits = "Watts";
    let powerAbs = Math.abs(power);
    if (powerAbs < 100) {
      if (power >= 0) power = power.toFixed(2);
      else power = power.toFixed(1);
      powerUnits = "Watts";
    } else if (powerAbs < 1000) {
      if (power >= 0) power = power.toFixed(1);
      else power = power.toFixed(0);
      powerUnits = "Watts";
    } else if (powerAbs < 10000) {
      if (power >= 0) power = (power / 1000).toFixed(3);
      else power = (power / 1000).toFixed(2);
      powerUnits = "kW";
    } else if (powerAbs < 100000) {
      if (power >= 0) power = (power / 1000).toFixed(2);
      else power = (power / 1000).toFixed(1);
      powerUnits = "kW";
    } else if (powerAbs < 1000000) {
      if (power >= 0) power = (power / 1000).toFixed(1);
      else power = (power / 1000).toFixed(0);
      powerUnits = "kW";
    } else if (powerAbs < 10000000) {
      if (power >= 0) power = (power / 1000000).toFixed(3);
      else power = (power / 1000000).toFixed(2);
      powerUnits = "MW";
    } else if (powerAbs < 100000000) {
      if (power >= 0) power = (power / 1000000).toFixed(2);
      else power = (power / 1000000).toFixed(1);
      powerUnits = "MW";
    } else if (powerAbs < 1000000000) {
      if (power >= 0) power = (power / 1000000).toFixed(1);
      else power = (power / 1000000).toFixed(0);
      powerUnits = "MW";
    } else if (powerAbs < 10000000000) {
      if (power >= 0) power = (power / 1000000000).toFixed(3);
      else power = (power / 1000000000).toFixed(2);
      powerUnits = "GW";
    }
    this.setState({ power, powerUnits });
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });

    this.getSystemData();
  }

  handleChangeStart = (event) => {
    const startDate = event.setHours(0, 0, 0);
    this.setState({ startDate });
  };

  handleChangeEnd = (event) => {
    const endDate = event.setHours(0, 0, 0);
    this.setState({ endDate });
  };

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseRT = () => {
    this.setState({ anchorEl: null });
    const endDate = new Date().getTime() - 10;
    const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Real-time", startDate, endDate });
  };

  handleCloseInterval = () => {
    this.setState({ anchorEl: null });
    const endDate = new Date().getTime() - 10;
    const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Interval", startDate, endDate });
  };
  handleCloseToday = () => {
    this.setState({ anchorEl: null });
    const startDate = new Date().setHours(0, 0, 0);
    const endDate = new Date().getTime();
    //console.log("CloseToday StartDate:", startDate);
    //console.log("CloseToday EndDate:", endDate);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Today", startDate, endDate });
  };
  handleCloseYesterday = () => {
    this.setState({ anchorEl: null });
    const startDate = new Date().setHours(-24, 0, 0);
    const endDate = new Date().setHours(0, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Yesterday", startDate, endDate });
  };
  handleCloseWeek = () => {
    this.setState({ anchorEl: null });
    let myDay = new Date().getDay();
    let startDate = new Date();
    console.log("Day of the week", myDay);
    if (myDay !== 0) startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    else {
      myDay = 7;
      startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    }
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Week", startDate, endDate });
  };
  handleCloseLastWeek = () => {
    this.setState({ anchorEl: null });
    let myDay = new Date().getDay();
    let startDate = new Date();
    console.log("Day of the week", myDay);
    if (myDay !== 0) startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    else {
      myDay = 7;
      startDate = new Date().setHours(-24 * (myDay - 1), 0, 0);
    }
    const endDate = startDate;
    startDate = startDate - 7 * 24 * 3600 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Week", startDate, endDate });
  };
  handleCloseMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Month", startDate, endDate });
  };
  handleCloseLastMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const endDate = new Date(y, m, 1).setHours(0, 0, 0);
    let startDate = new Date();
    if (m > 0) startDate = new Date(y, m - 1, 1).setHours(0, 0, 0);
    else startDate = new Date(y - 1, 11, 1).setHours(0, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Month", startDate, endDate });
  };
  handleCloseQuarter = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    let m = myDay.getMonth();
    if (m >= 0 && m <= 2) m = 0;
    else if (m >= 3 && m <= 5) m = 3;
    else if (m >= 6 && m <= 8) m = 6;
    else m = 9;
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Quarter", startDate, endDate });
  };
  handleCloseYear = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    const startDate = new Date(y, 0, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Year", startDate, endDate });
  };
  handleCloseDR = () => {
    this.setState({ anchorEl: null });
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Date Range" });
  };
  handleCloseDaily = () => {
    this.setState({ anchorEl: null });
    console.log("Dashboard Daily");
    this.setState({ modeBox: "Daily" });
    this.props.history.push(
      `/systems/dashboardDaily/${this.state.handleID}/${this.state.systemConfiguration}`
    );
  };

  showNumbers = (e) => {
    e.preventDefault();
    let showNumbers = this.state.showNumbers;
    showNumbers = !showNumbers;
    const showNumbersBoolean = showNumbers;
    localStorage.setItem(
      "showNumbersBoolean",
      JSON.stringify(showNumbersBoolean)
    );
    console.log("showNumbers", showNumbers);
    if (showNumbers) localStorage.setItem("showNumbers", "show");
    else localStorage.setItem("showNumbers", "dontShow");
    this.setState({ showNumbers });
  };
  render() {
    //console.log("User Role", this.state.userRole);
    //  console.log("End Date", this.state.endDate);
    return (
      <React.Fragment>
        <ScrollView style={{ height: "200vh" }}>
          <div
            id="mainNodeVu"
            className="containerSimplNodeVu"
            style={{
              position: "relative",
              marginTop: "120px",
              overflowY: "scrollY",
              zIndex: 1,
            }}
          >
            <div className="containerSimplView">
              <p
                className="basicParagraph"
                style={{
                  width: "350px",
                  fontSize: "12px",
                  color: "#777575",
                  textAlign: "center",
                  paddingRight: "25px",
                }}
              >
                {this.state.systemName}
              </p>

              <div className="mainMenuIcons">
                <MainMenu
                  systemName={this.state.systemName}
                  systemHumanTime={this.state.systemHumanTime}
                  commMode={this.state.commMode}
                  wifiSignalStrength={this.state.wifiSignalStrength}
                  slaveCount={this.state.slaveCount}
                  loopCount={this.state.loopCount}
                  outageFlag={this.state.outageFlag}
                  acCoupledBackUp={this.state.acCoupledBackUp}
                  moduleDataValid={this.state.moduleDataValid}
                  systemConfiguration={this.state.systemConfiguration}
                  inCompleteData={this.state.inCompleteData}
                />
                <table>
                  <tr style={{ height: "40px" }}>
                    {(this.state.userRole === "operatorOf" ||
                      this.state.userRole === "companyAdmin") && (
                      <td style={{ width: "140px", padding: "0", margin: "0" }}>
                        {this.state.modeBox === "Date Range" && (
                          <div>
                            <DatePicker
                              className="ui-datepicker"
                              selected={this.state.startDate}
                              selectsStart
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              onChange={this.handleChangeStart}
                            />
                            <DatePicker
                              className="ui-datepicker"
                              selected={this.state.endDate}
                              selectsEnd
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              onChange={this.handleChangeEnd}
                            />
                          </div>
                        )}
                      </td>
                    )}

                    {(this.state.userRole === "operatorOf" ||
                      this.state.userRole === "companyAdmin") && (
                      <td>
                        <p
                          className="indicateParagraph"
                          style={{
                            position: "relative",
                            marginRight: "0px",
                            borderWidth: "0px",
                          }}
                        >
                          {" "}
                          {this.state.modeBox}
                        </p>
                      </td>
                    )}

                    {(this.state.userRole === "operatorOf" ||
                      this.state.userRole === "companyAdmin") && (
                      <td style={{ position: "relative", marginRight: "0px" }}>
                        <SimpleMenu
                          onClickingMenu={this.handleClickMenu}
                          onChoosingRT={this.handleCloseRT}
                          onChoosingInterval={this.handleCloseInterval}
                          onChoosingToday={this.handleCloseToday}
                          onChoosingYesterday={this.handleCloseYesterday}
                          onChoosingWeek={this.handleCloseWeek}
                          onChoosingLastWeek={this.handleCloseLastWeek}
                          onChoosingMonth={this.handleCloseMonth}
                          onChoosingLastMonth={this.handleCloseLastMonth}
                          onChoosingQuarter={this.handleCloseQuarter}
                          onChoosingYear={this.handleCloseYear}
                          onChoosingDR={this.handleCloseDR}
                          onChoosingDaily={this.handleCloseDaily}
                          anchorState={this.state.anchorEl}
                        />
                      </td>
                    )}

                    {(this.state.userRole === "operatorOf" ||
                      this.state.userRole === "companyAdmin") && (
                      <td style={{ position: "relative", marginRight: "0px" }}>
                        <button
                          className="btn btn-outline-light"
                          style={{
                            borderWidth: "0px",
                            padding: "0px",
                            float: "right",
                            marginRight: "5px",
                            position: "relative",
                            right: "0px",
                          }}
                        >
                          <Link
                            to={`/systems/dashboardChart/${this.props.match.params.systemHandleID}
                        /${this.props.match.params.companyName}
                        /${this.state.startDate}
                        /${this.state.endDate}
                        /${this.props.match.params.userRole}`}
                          >
                            <FontAwesomeIcon
                              icon="chart-line"
                              size="sm"
                              color="orange"
                              title="System Information"
                            />
                          </Link>
                        </button>
                      </td>
                    )}

                    {(this.state.userRole === "operatorOf" ||
                      this.state.userRole === "companyAdmin") && (
                      <td style={{ position: "relative", marginRight: "0px" }}>
                        <CostNSavingsModal
                          pvIntervalTotal={this.state.pvIntervalTotal}
                          pvEnergyValue={this.state.pvEnergyValue}
                          pvDemandValue={this.state.pvDemandValue}
                          battIntervalTotal={this.state.battIntervalTotal}
                          battEnergyValue={this.state.battEnergyValue}
                          battDemandValue={this.state.battDemandValue}
                          gridIntervalTotal={this.state.gridIntervalTotal}
                          gridEnergyValue={this.state.gridEnergyValue}
                          gridDemandValue={this.state.gridDemandValue}
                          bldgIntervalTotal={this.state.bldgIntervalTotal}
                          bldgEnergyValue={this.state.bldgEnergyValue}
                          bldgDemandValue={this.state.bldgDemandValue}
                          savingsIntervalTotal={this.state.savingsIntervalTotal}
                          savingsEnergyValue={this.state.savingsEnergyValue}
                          gridMaxDemand={this.state.gridMaxDemand}
                          bldgMaxDemand={this.state.bldgMaxDemand}
                          demandSavings={this.state.demandSavings}
                          totalSavings={this.state.totalSavings}
                          gridMaxKW={this.state.gridMaxKW}
                          bldgMaxKW={this.state.bldgMaxKW}
                          totalUsage={this.state.totalUsage}
                          netCosts={this.state.netCosts}
                          percentEnergySavings={this.state.percentEnergySavings}
                          percentDemandSavings={this.state.percentDemandSavings}
                          percentTotalSavings={this.state.percentTotalSavings}
                        />
                      </td>
                    )}

                    {(this.state.userRole === "operatorOf" ||
                      this.state.userRole === "companyAdmin") && (
                      <td style={{ position: "relative", marginRight: "0px" }}>
                        <button
                          onClick={this.showNumbers}
                          className="btn btn-outline-light"
                          style={{ padding: "0px", borderWidth: "0px" }}
                        >
                          <FontAwesomeIcon
                            icon="tachometer-alt"
                            size="sm"
                            color="tomato"
                            title="Indicators"
                          />
                        </button>
                      </td>
                    )}

                    {this.state.userRole !== "ownerOf" && (
                      <td
                        style={{
                          position: "fixed",
                          right: "22px",
                          marginTop: "7px",
                        }}
                      >
                        <button
                          className="btn btn-outline-light"
                          style={{
                            borderWidth: "0px",
                            padding: "0px",
                            float: "right",
                            marginRight: "5px",
                            position: "relative",
                            right: "0px",
                          }}
                        >
                          <Link
                            to={`/systems/dashboardWideSlaves/${this.props.match.params.systemHandleID}
                        /${this.props.match.params.companyName}`}
                          >
                            <FontAwesomeIcon
                              icon="search"
                              size="sm"
                              color="brown"
                              title="Subsystems"
                            />
                          </Link>
                        </button>
                      </td>
                    )}

                    <td
                      style={{
                        position: "fixed",
                        right: "3px",
                        marginTop: "7px",
                      }}
                    >
                      <SystemInfoModal
                        loopCount={this.state.loopCount}
                        fwVersionMaster={this.state.fwVersionMaster}
                        fwAppName={this.state.fwAppName}
                        slaveCount={this.state.slaveCount}
                        companyName={this.state.companyName}
                        energyRate={this.state.energyRate}
                        demandRate={this.state.demandRate}
                        onPeakEnergy={this.state.onPeakEnergy}
                        onPeakDemand={this.state.onPeakDemand}
                        systemStatus={this.state.systemStatus}
                        HandleID={this.props.match.params.systemHandleID}
                        systemDescription={this.state.systemDescription}
                        systemConfiguration={this.state.systemConfiguration}
                        systemProduct={this.state.systemProduct}
                        systemType={this.state.systemType}
                        paramUpdateRate={this.state.paramUpdateRate}
                        dataVerbosity={this.state.dataVerbosity}
                        dataFrequency={this.state.dataFrequency}
                        serialNumber={this.state.serialNumber}
                        macAddress={this.state.macAddress}
                        systemName={this.state.systemName}
                        commProvider={this.state.commProvider}
                        productSN={this.state.productSN}
                        emaSN={this.state.emaSN}
                        attachedSimplModuleSN={this.state.attachedSimplModuleSN}
                        attachedSimplModuleHandle={
                          this.state.attachedSimplModuleHandle
                        }
                        moduleDataValid={this.state.moduleDataValid}
                        ssId={this.state.wifiSSID}
                        MeshId={this.state.MeshId}
                        outageFlag={this.state.outageFlag}
                        outageCount={this.state.outageCount}
                        acCoupledBackUp={this.state.acCoupledBackUp}
                        freeHeap={this.state.freeHeap}
                        minHeap={this.state.minHeap}
                        hwConfig={this.state.hwConfig}
                        signalStrength={this.state.signalStrength}
                        signalQuality={this.state.signalQuality}
                      />
                    </td>
                  </tr>
                </table>
              </div>

              <EnergyIndepProg
                gridPowerProgress={this.state.gridPowerProgress}
                solarPowerProgress={this.state.solarPowerProgress}
                battPowerProgress={this.state.battPowerProgress}
              />
              <div
                style={{
                  width: "350px",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "10px",
                  marginBottom: "150px",
                  marginLeft: "8px",
                  //zIndex: 1,
                }}
              >
                <SystemIcons
                  systemConfiguration={this.state.systemConfiguration}
                  systemHandleID={this.props.match.params.systemHandleID}
                  companyName={this.props.match.params.companyName}
                />
                <EnergyAnimation
                  dataTimeInValid={this.state.dataTimeInvalid}
                  batteryPower={this.state.batteryPower}
                  bSVU={this.state.bSVU}
                  cSVU={this.state.cSVU}
                  eSVU={this.state.eSVU}
                />
              </div>
              <div
                className="mainMenuIcons"
                style={{
                  paddingBottom: "10px",
                  transform: "translateY(410px)",
                }}
              >
                <SimplFooter
                  handleID={this.props.match.params.systemHandleID}
                  companyName={this.props.match.params.companyName}
                  userRole={this.props.match.params.userRole}
                  systemSN={this.props.match.params.systemSN}
                />
              </div>

              <SimplVuNumbers
                energyIndependence={this.state.energyIndependence}
                dataTimeInvalid={this.state.dataTimeInvalid}
                showNumbers={this.state.showNumbers}
                aSVU={this.state.aSVU}
                bSVU={this.state.bSVU}
                cSVU={this.state.cSVU}
                dSVU={this.state.dSVU}
                eSVU={this.state.eSVU}
                pHouse={this.state.pHouse}
                aSVUPowerUnits={this.state.aSVUPowerUnits}
                bSVUPowerUnits={this.state.bSVUPowerUnits}
                cSVUPowerUnits={this.state.cSVUPowerUnits}
                dSVUPowerUnits={this.state.dSVUPowerUnits}
                eSVUPowerUnits={this.state.eSVUPowerUnits}
                pHousePowerUnits={this.state.pHousePowerUnits}
                batteryPower={this.state.batteryPower}
              />

              {!this.state.firstLoading &&
                this.state.systemConfiguration === "0" && (
                  <SimplSOC
                    overallSOC={this.state.overallSOC}
                    rangeHours={this.state.rangeHours}
                  />
                )}

              {this.state.firstLoading && (
                <div
                  class="d-flex justify-content-center"
                  style={{ transform: "translate(0px,100px)" }}
                >
                  <div
                    class="spinner-border text-primary m-2"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </ScrollView>
      </React.Fragment>
    );
  }
}
export default SimplVu;
