import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getAllUsers, getUser, restoreUser } from "../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import { getCompanyUsers, getUserHistory } from "../services/companyService";
import Modal from "react-bootstrap/Modal";
import { masterCompany } from "../config.json";
import BackGroundRectangle from "./backgroundRect";
import { toast } from "react-toastify";
import ReactExport from "react-data-export";
import { ScrollView } from "@cantonjs/react-scroll-view";

// get external components
import UserHistory from "./userHistory";
import UserAccessControl from "./userAccessControl";
import AddUserToCompany from "./userCompany";

// For subadmin privileges
import {
  addSubAdmin,
  revokeSubAdmin,
  addUserToCompany,
} from "../services/userService";

// For handling excel file
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { registerUser } from "../services/userService";
import {
  faImage,
  faFolderArrowUp,
  // faUpFromLine,
  faArrowUpFromBracket,
  faFileArrowDown,
} from "@fortawesome/free-solid-svg-icons";

class ManageUsers extends Component {
  state = {
    optionMode: "Search for User",
    company: null,
    user: {},
  };

  options = [
    "Search for User",
    "User Access Control",
    "Add a registered User",
    "User History",
    "Re-Register User",
  ];

  async componentDidMount() {
    const companyName = this.props.match.params.companyName;
    const user = await getUser();

    console.log("User", user);
    this.setState({ company: companyName });
    this.setState({ user: user.data.data });
    this.setState({ userCompany: user.data.data.company });

    // For user template excel file
    let excelTemplate = [0];
    // for (let i = 0; i < filteredUser.length; i++) {
    excelTemplate[0] = JSON.parse(JSON.stringify({}));
    this.setState({ excelTemplate });
  }

  handleOptionSelected(event) {
    const value = event.target.value;
    this.setState({ optionMode: value });
  }

  // File handler for user's excel file
  fileHandler = async (event) => {
    let fileObj = event.target.files[0];
    // if (fileObj.size < 10000) toast.error("Error: Empty file.");

    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Checks if the excel file has data in it
        if (!resp.rows.length || !resp.rows[0] || resp.rows[0].length < 2) {
          toast.error("Error: Invalid or empty Excel file.");
          return;
        }

        // Checks if the excel file has the correct format
        if (resp.rows[0][1] === "First Name" || resp.rows.length > 1) {
          toast.success(
            "File Uploaded Successfully. Please wait for a moment."
          );
          for (let i = 1; i < resp.rows.length; i++) {
            try {
              if (resp.rows[i][2] === "" || resp.rows[i][2] === undefined) {
                toast.error("Error: File is empty or missing information.");
                return;
              }
              const res = await registerUser(
                resp.rows[i][2], // Email address
                resp.rows[i][2] + "1234", // Password
                resp.rows[i][0], // First Name
                resp.rows[i][1], // Last Name
                resp.rows[i][2], // Username
                resp.rows[i][3] // Phone Number
              );
              if (!res || res.status !== 200) {
                continue;
                // toast.warn(`User ${resp.rows[i][2]} could not be added.`);
              } else {
                // toast.success("User " + resp.rows[i][2] + " added.");
              }
            } catch (err) {
              // toast.error(`User ${resp.rows[i][2]} already exists.`);
            }

            try {
              const res = await addUserToCompany(resp.rows[i][2]);
              if (res.status !== 200) continue;
              // toast.error("Error adding user to company.");
              // else toast.success("User added to company.");
            } catch (err) {
              // toast.error("Error adding user to company.");
            }

            if (resp.rows[i][4] && resp.rows[i][4].toLowerCase() === "yes") {
              try {
                const res = await addSubAdmin(
                  resp.rows[i][2],
                  this.state.company
                );
                if (res.status !== 200)
                  toast.error(
                    "Error granting subadmin privileges to " + resp.rows[i][2]
                  );
                // else toast.success("Subadmin privileges granted.");
              } catch (err) {
                // toast.error("Error granting subadmin privileges.");
              }
            }
          }
          toast.success("Users added successfully.");
        } else {
          toast.error("Error: Invalid excel file format.");
        }
      }
    });
  };

  render() {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            paddingBottom: "100px",
            minHeight: "600px",
            maxHeight: "auto",
            zIndex: 1,
          }}
        >
          <>
            {/* For excel file template and user uploads*/}
            {!this.state.user.subadmin && this.state.user.admin && (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    className="basicParagraph"
                    style={{
                      fontSize: "12px",
                      color: "#B2BEB5",
                    }}
                  >
                    {" "}
                    Download template and upload excel file to register multiple
                    users
                  </p>
                  <ExcelFile
                    filename="User_Registration_Template"
                    element={
                      <button
                        className="btn btn-outline-light"
                        style={{
                          padding: "0px",
                          float: "right",
                          marginRight: "20px",
                          marginLeft: "20px",
                          marginTop: "00px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faFileArrowDown}
                          size="lg"
                          color="green"
                          title={"Download Template"}
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      data={this.state.excelTemplate}
                      name="UserTemplate"
                    >
                      <ExcelColumn label="First Name" value="firstName" />
                      <ExcelColumn label="Last Name" value="lastName" />
                      <ExcelColumn label="Email" value="email" />
                      <ExcelColumn label="Phone" value="phone" />
                      <ExcelColumn
                        label="Sub-Admin (yes / no)"
                        value="subAdmin"
                      />
                    </ExcelSheet>
                  </ExcelFile>

                  <label style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon
                      icon={faArrowUpFromBracket}
                      size="lg"
                      color={"green"}
                      title={"Upload Excel File"}
                      style={{ cursor: "pointer" }}
                    />
                    <input
                      type="file"
                      onChange={this.fileHandler.bind(this)}
                      className="inputTariff"
                      style={{ display: "none" }}
                      accept=".xls,.xlsx"
                    />
                  </label>
                </div>
                <hr />
              </>
            )}

            <p
              className="text-center basicParagraph text-center"
              style={{
                width: "100%",
                fontSize: "12px",
                color: "#2d5c88",
                marginTop: "20px",
              }}
            >
              Please select an option
            </p>
            <div
              style={{
                display: "flex",
                marginBottom: "2px",
                justifyContent: "center",
              }}
            >
              <select
                className="form-control2"
                value={this.state.optionMode}
                name={this.state.optionMode}
                onChange={(event) => this.handleOptionSelected(event)}
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#784feb",
                  width: "200px",
                }}
              >
                {this.options.map((element) => (
                  <>
                    {element === "Search for User" ? (
                      <option selected value={element}>
                        {element}
                      </option>
                    ) : (
                      <option value={element}>{element}</option>
                    )}
                  </>
                ))}
              </select>
            </div>

            {this.state.optionMode === "Search for User" && (
              <div>
                {this.state.company ? (
                  <SearchForUser companyName={this.state.company} />
                ) : (
                  <div>loading</div>
                )}
              </div>
            )}
            {this.state.optionMode === "User Access Control" && (
              <div>
                <hr />
                {this.state.company ? (
                  <UserAccessControl companyName={this.state.company} />
                ) : (
                  <div>loading</div>
                )}
              </div>
            )}
            {this.state.optionMode === "Add a registered User" && (
              <div>
                <hr />
                {this.state.company ? (
                  <AddUserToCompany companyName={this.state.company} />
                ) : (
                  <div>loading</div>
                )}
              </div>
            )}
            {this.state.optionMode === "User History" && (
              <div>
                {this.state.company ? (
                  <UserHistory companyName={this.state.company} />
                ) : (
                  <div>loading</div>
                )}
              </div>
            )}
            {this.state.optionMode === "Re-Register User" && (
              <div>
                {(this.state.user.admin &&
                  this.state.user.company === this.state.company) ||
                this.state.user.company === masterCompany ? (
                  <>
                    {this.state.company ? (
                      <RegAndUnregUsers companyName={this.state.company} />
                    ) : (
                      <div>loading</div>
                    )}
                  </>
                ) : (
                  <div>
                    <p
                      className="basicParagraph text-center"
                      style={{
                        fontSize: "12px",
                        color: "#2D5C88",
                      }}
                    >
                      You have no permissions to access this menu
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        </div>
      </React.Fragment>
    );
  }
}

class RegAndUnregUsers extends Component {
  state = {
    companyUsers: [],
    allUsers: [],
    message: "",
    filteredUser: [],
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);

    const allUsers = await getAllUsers();
    const companyUsers = await getCompanyUsers(this.props.companyName);

    this.setState({ companyUsers: companyUsers.data.data });
    this.setState({ allUsers: allUsers.data.data });

    let usersLastlogin;
    // let userHistory = await getUserHistory(this.props.match.params.id);
    // this.setState({ userHistory: userHistory.data.history });

    // if (userHistory.data.history.user_history) {
    //   usersLastlogin = userHistory.data.history.user_history.map((history) => {
    //     return this.findLastLogin(history.username);
    //   });

    //   this.state.companyUsers.forEach((user) => {
    //     usersLastlogin.forEach((history) => {
    //       if (user.username === history.username) {
    //         user.lastLogin = history.date;
    //       }
    //     });
    //   });
    //   this.setState(companyUsers);
    // }
  }

  handleReRegister = async (e) => {
    e.preventDefault();
    const userNameReRegister = this.state.userNameReRegister;
    await restoreUser(userNameReRegister);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const searchMethod = this.state.searchMethod;
    const allUsers = (await getAllUsers()).data.data;
    let filteredUser = [];
    if (this.state.searchField === "*") filteredUser = allUsers;
    else if (searchMethod === "by lastname")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.lastName === this.state.searchField
      );
    else if (searchMethod === "by firstname")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.firstName === this.state.searchField
      );
    else if (searchMethod === "by email")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.emailAddress === this.state.searchField
      );
    else if (searchMethod === "by username")
      filteredUser = allUsers.filter(
        (thisUser) => thisUser.username === this.state.searchField
      );
    if (filteredUser.length === 0) {
      this.setState({ message: "Error: User not found." });
      toast.error("Error: User not found.");
    } else {
      this.setState({
        message: "User(s) were found.",
      });
      this.setState({ filteredUser });

      toast.success("Success: Users found.");
      let excelDataSet = [0];
      for (let i = 0; i < filteredUser.length; i++) {
        excelDataSet[i] = JSON.parse(
          JSON.stringify({
            userName: filteredUser[i].username,
            firstName: filteredUser[i].firstName,
            lastName: filteredUser[i].lastName,
            email: filteredUser[i].emailAddress,
            phone: filteredUser[i].phoneNumber,
            admin: filteredUser[i].admin,
            verified: filteredUser[i].verified,
            company: filteredUser[i].company,
            updated: filteredUser[i].updated_at,
            created: filteredUser[i].created_at,
          })
        );
      }
      console.log("Excel Data JSON", excelDataSet);
      this.setState({ excelDataSet });
    }
  };

  handleSearchMethod = (e) => {
    const searchMethod = e.currentTarget.value;
    this.setState({ searchMethod });
  };
  handleChange = (e) => {
    const searchField = e.currentTarget.value;
    this.setState({ searchField });
  };

  handleChangeUserName = (e) => {
    const userNameReRegister = e.currentTarget.value;
    this.setState({ userNameReRegister });
  };
  // Find user's last login from the user history in companies table
  findLastLogin = (username) => {
    let userLastLogin;
    if (this.state.userHistory) {
      userLastLogin = this.state.userHistory.user_history.find(
        (history) => username === history.username
      );
      if (userLastLogin) {
        return userLastLogin;
      }
    }
  };

  render() {
    const userCount = this.state.companyUsers.length;
    const companyName = this.props.companyName;
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          style={{
            width: "600px",
            height: "800px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginBottom: "150px",
            height: "auto",
            zIndex: 1,
          }}
        >
          <div
            style={{
              width: "600px",
              // height: "5000px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginBottom: "350px",
              zIndex: 1,
            }}
          >
            <hr />
            <div className="d-flex justify-content-center pb-2 mb-3">
              <p
                className="basicParagraph text-center"
                style={{
                  fontSize: "12px",
                  color: "#2D5C88",
                }}
              >
                {" "}
                Re-register Users
              </p>
            </div>

            <form onSubmit={this.handleReRegister}>
              <label className="basicParagraph" htmlFor="">
                Enter User's username to Re-register:
              </label>
              <input
                value={this.state.userNameReRegister}
                onChange={this.handleChangeUserName}
                id="userLastName"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Re-Register This User
              </button>
            </form>
            {/* <hr />
            <div>
              <label
                className="basicParagraph"
                style={{ height: "30px", width: "150px" }}
              >
                Select a search method:
              </label>
              <select
                className="form-control2"
                value={this.state.searchMethod}
                onChange={this.handleSearchMethod}
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: "0px",
                  borderBottom: "2px solid lightgrey",
                }}
              >
                <option value="Select Search">Select Search</option>
                <option value="by username">Username</option>
                <option value="by firstname">First Name</option>
                <option value="by lastname">Last Name</option>
                <option value="by email">Email</option>
              </select>
            </div>
            {/* {this.state.searchMethod === "by lastname" && (*/}
            {/* <form onSubmit={this.handleSubmit}>
              <label className="basicParagraph" htmlFor="">
                Enter User's Information Here:
              </label>
              <input
                value={this.state.userLastName}
                onChange={this.handleChange}
                id="userLastName"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Search for User
              </button>
            </form>  */}
            {/*  )}*/}

            <hr />

            {this.state.message === "User(s) were found." && (
              <div>
                <h4>The following users were found:</h4>
                <p className="basicParagraph" style={{ marginLeft: "8%" }}>
                  Note: This list is only available to {this.props.companyName}:
                </p>
                <ScrollView>
                  <table
                    className="table"
                    style={{ paddingBottom: "100px", marginBottom: "100px" }}
                  >
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>email</th>
                        <th>
                          <td>
                            <ExcelFile
                              filename="Simpl Global User Data"
                              element={
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    float: "right",
                                    marginRight: 150,
                                    marginTop: "00px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon="file-excel"
                                    size="lg"
                                    color="green"
                                    title="Export User info to excel"
                                  />
                                </button>
                              }
                            >
                              <ExcelSheet
                                data={this.state.excelDataSet}
                                name="UserData"
                              >
                                <ExcelColumn
                                  label="Username"
                                  value="userName"
                                />
                                <ExcelColumn
                                  label="First Name"
                                  value="firstName"
                                />
                                <ExcelColumn
                                  label="Last Name"
                                  value="lastName"
                                />
                                <ExcelColumn label="Email" value="email" />
                                <ExcelColumn label="Phone" value="phone" />
                                <ExcelColumn label="Admin" value="admin" />
                                <ExcelColumn
                                  label="Verified"
                                  value="verified"
                                />
                                <ExcelColumn
                                  label="Company Name"
                                  value="company"
                                />
                                <ExcelColumn label="Created" value="created" />
                                <ExcelColumn label="Updated" value="updated" />
                              </ExcelSheet>
                            </ExcelFile>
                          </td>{" "}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.filteredUser.map((user) => (
                        <tr key={user.username}>
                          <td>{user.username}</td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.emailAddress}</td>
                          <td>
                            <Link to={`/unregUser/${user.username}`}>
                              <FontAwesomeIcon
                                icon="trash-alt"
                                size="lg"
                                color="red"
                                title="Delete User"
                              />
                            </Link>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ScrollView>
              </div>
            )}
          </div>
        </div>
        {/* <BackGroundRectangle />*/}
      </React.Fragment>
    );
  }
}

class SearchForUser extends Component {
  state = {
    companyUsers: [],
    allUsers: [],
    message: "",
    filteredUser: [],
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    setJwt(jwt);

    const allUsers = await getAllUsers();
    const companyUsers = await getCompanyUsers(this.props.companyName);

    this.setState({ companyUsers: companyUsers.data.data });
    this.setState({ allUsers: allUsers.data.data });

    let usersLastlogin;

    let excelTemplate = [0];
    // for (let i = 0; i < filteredUser.length; i++) {
    excelTemplate[0] = JSON.parse(
      JSON.stringify({
        // userName: filteredUser[i].username,
        // firstName: "First Name",
        // lastName: "Last Name",
        // email: "Email",
        // phone: "Phone",
        // admin: filteredUser[i].admin,
        // verified: filteredUser[i].verified,
        // company: filteredUser[i].company,
        // updated: filteredUser[i].updated_at,
        // created: filteredUser[i].created_at,
      })
    );
    // }
    console.log("Excel Data JSON", excelTemplate);
    this.setState({ excelTemplate });
    // let userHistory = await getUserHistory(this.props.match.params.id);
    // this.setState({ userHistory: userHistory.data.history });

    // if (userHistory.data.history.user_history) {
    //   usersLastlogin = userHistory.data.history.user_history.map((history) => {
    //     return this.findLastLogin(history.username);
    //   });

    //   this.state.companyUsers.forEach((user) => {
    //     usersLastlogin.forEach((history) => {
    //       if (user.username === history.username) {
    //         user.lastLogin = history.date;
    //       }
    //     });
    //   });
    //   this.setState(companyUsers);
    // }
  }

  handleReRegister = async (e) => {
    e.preventDefault();
    const userNameReRegister = this.state.userNameReRegister;
    await restoreUser(userNameReRegister);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const searchMethod = this.state.searchMethod;
    const allUsers = (await getAllUsers()).data.data;
    let filteredUser = [];
    if (this.state.searchField === "*") filteredUser = allUsers;
    else if (searchMethod === "by lastname")
      filteredUser = allUsers.filter((thisUser) =>
        thisUser.lastName
          .toLowerCase()
          .includes(this.state.searchField.toLowerCase())
      );
    else if (searchMethod === "by firstname")
      filteredUser = allUsers.filter((thisUser) =>
        thisUser.firstName
          .toLowerCase()
          .includes(this.state.searchField.toLowerCase())
      );
    else if (searchMethod === "by email")
      filteredUser = allUsers.filter((thisUser) =>
        thisUser.emailAddress
          .toLowerCase()
          .includes(this.state.searchField.toLowerCase())
      );
    else if (searchMethod === "by username")
      filteredUser = allUsers.filter((thisUser) =>
        thisUser.username
          .toLowerCase()
          .includes(this.state.searchField.toLowerCase())
      );
    if (filteredUser.length === 0) {
      this.setState({ message: "Error: User not found." });
      toast.error("Error: User not found.");
    } else {
      this.setState({
        message: "User(s) were found.",
      });
      this.setState({ filteredUser });
      toast.success("Success: Users found.");
      let excelDataSet = [0];
      for (let i = 0; i < filteredUser.length; i++) {
        excelDataSet[i] = JSON.parse(
          JSON.stringify({
            userName: filteredUser[i].username,
            firstName: filteredUser[i].firstName,
            lastName: filteredUser[i].lastName,
            email: filteredUser[i].emailAddress,
            phone: filteredUser[i].phoneNumber,
            admin: filteredUser[i].admin,
            verified: filteredUser[i].verified,
            company: filteredUser[i].company,
            updated: filteredUser[i].updated_at,
            created: filteredUser[i].created_at,
          })
        );
      }
      console.log("Excel Data JSON", excelDataSet);
      this.setState({ excelDataSet });
    }
  };

  handleSearchMethod = (e) => {
    const searchMethod = e.currentTarget.value;
    this.setState({ searchMethod });
  };
  handleChange = (e) => {
    const searchField = e.currentTarget.value;
    this.setState({ searchField });
  };

  handleChangeUserName = (e) => {
    const userNameReRegister = e.currentTarget.value;
    this.setState({ userNameReRegister });
  };
  // Find user's last login from the user history in companies table
  findLastLogin = (username) => {
    let userLastLogin;
    if (this.state.userHistory) {
      userLastLogin = this.state.userHistory.user_history.find(
        (history) => username === history.username
      );
      if (userLastLogin) {
        return userLastLogin;
      }
    }
  };

  // Checkbox for subadmin privileges
  manageSub = async (e, username) => {
    if (this.state.companyName !== this.state.userCompany) {
      toast.error("User should be from that company to change roles.");
    } else if (e.target.checked === true) {
      const res = await addSubAdmin(username, this.props.companyName);
      if (res.status !== 200)
        toast.error("Only the company admin can give subadmin privileges.");
      else toast.success("Subadmin privileges granted to " + username + ".");
      console.log("addSubadmin response", res);
    } else {
      const res = await revokeSubAdmin(username, this.props.companyName);
      if (res.status !== 200)
        toast.error("Only the company admin can give subadmin privileges.");
      else toast.success("Subadmin privileges revoked from " + username + ".");
      console.log("Revoke subadmin response", res);
    }
  };

  // File handler for excel file
  fileHandler = async (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        if (resp.rows.length > 1) {
          for (let i = 1; i < resp.rows.length; i++) {
            try {
              const res = await registerUser(
                "Simpl" + resp.rows[i][2].split("@")[0],
                "Simpl" + resp.rows[i][2].split("@")[0] + "1234",
                resp.rows[i][0],
                resp.rows[i][1],
                resp.rows[i][2],
                resp.rows[i][3]
              );
              console.log("register user response", res);
              if (!res || res.status !== 200) {
                toast.warn(
                  `User ${resp.rows[i][2].split("@")[0]} already exists.`
                );
              } else {
                toast.success(
                  "User " + resp.rows[i][2].split("@")[0] + " added."
                );
              }
            } catch (err) {
              toast.error(`User ${resp.rows[i][0]} already exists.`);
              console.error("Error registering user:", err);
            }
          }
        }
        // console.log("Excel rendered: ", resp.rows);
      }
    });
  };

  render() {
    const userCount = this.state.companyUsers.length;
    const companyName = this.props.companyName;
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          style={{
            width: "600px",
            height: "700px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginBottom: "150px",
            height: "auto",
            zIndex: 1,
          }}
        >
          <div
            style={{
              width: "600px",
              // height: "5000px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginBottom: "350px",
              zIndex: 1,
            }}
          >
            {/* <hr />
            <div className="d-flex justify-content-center pb-2 mb-3">
              <p
                className="basicParagraph text-center"
                style={{
                  fontSize: "12px",
                  color: "#2D5C88",
                }}
              >
                {" "}
                Search for User
              </p>
            </div>

            <hr></hr>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                className="basicParagraph"
                style={{
                  fontSize: "12px",
                  color: "#B2BEB5",
                }}
              >
                {" "}
                Download template and upload excel file to register multiple
                users
              </p>
              <ExcelFile
                filename="User Registration Template"
                element={
                  <button
                    className="btn btn-outline-light"
                    style={{
                      padding: "0px",
                      float: "right",
                      marginRight: "20px",
                      marginTop: "00px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowDown}
                      size="lg"
                      color="green"
                      title="Download Template"
                    />
                  </button>
                }
              >
                <ExcelSheet data={this.state.excelTemplate} name="UserTemplate">
                  <ExcelColumn label="First Name" value="firstName" />
                  <ExcelColumn label="Last Name" value="lastName" />
                  <ExcelColumn label="Email" value="email" />
                  <ExcelColumn label="Phone" value="phone" />
                </ExcelSheet>
              </ExcelFile>

              <label>
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  size="lg"
                  color="green"
                  title="Upload Excel File"
                  style={{ cursor: "pointer" }}
                />
                <input
                  type="file"
                  onChange={this.fileHandler.bind(this)}
                  className="inputTariff"
                  style={{ display: "none" }}
                  accept=".xls,.xlsx"
                />
              </label>
            </div>
            <hr></hr> */}

            {/* <form onSubmit={this.handleReRegister}>
              <label className="basicParagraph" htmlFor="">
                Enter User's username to Re-register:
              </label>
              <input
                value={this.state.userNameReRegister}
                onChange={this.handleChangeUserName}
                id="userLastName"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Re-Register This User
              </button>
            </form> */}
            {/* <hr /> */}
            <div>
              <label
                className="basicParagraph"
                style={{ height: "30px", width: "150px" }}
              >
                Select a search method:
              </label>
              <select
                className="form-control2"
                value={this.state.searchMethod}
                onChange={this.handleSearchMethod}
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  border: "none",
                  borderRadius: "0px",
                  borderBottom: "2px solid lightgrey",
                }}
              >
                <option value="Select Search">Select Search</option>
                <option value="by username">Username</option>
                <option value="by firstname">First Name</option>
                <option value="by lastname">Last Name</option>
                <option value="by email">Email</option>
              </select>
            </div>
            {/* {this.state.searchMethod === "by lastname" && (*/}
            <form onSubmit={this.handleSubmit}>
              <label className="basicParagraph" htmlFor="">
                Enter User's Information Here:
              </label>
              <input
                value={this.state.userLastName}
                onChange={this.handleChange}
                id="userLastName"
                type="text"
                className="form-control2"
              />

              <button
                className="basicButton"
                style={{ width: "200px", marginTop: "10px" }}
              >
                Search for User
              </button>
            </form>
            {/*  )}*/}

            <hr />

            {this.state.message === "User(s) were found." && (
              <div>
                <h4>The following users were found:</h4>
                <p className="basicParagraph" style={{ marginLeft: "8%" }}>
                  Note: This list is only available to {this.props.companyName}:
                </p>
                <ScrollView>
                  <table
                    className="table"
                    style={{
                      paddingBottom: "100px",
                      marginBottom: "100px",
                      width: "100%",
                      tableLayout: "fixed",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>email</th>
                        <th>Sub Admin</th>
                        <th>Info</th>
                        <th>
                          {/* <td> */}
                          <ExcelFile
                            filename="Simpl Global User Data"
                            element={
                              <button
                                className="btn btn-outline-light"
                                style={{
                                  padding: "0px",
                                  // float: "right",
                                  marginRight: 150,
                                  marginTop: "00px",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="file-excel"
                                  size="lg"
                                  color="green"
                                  title="Export User info to excel"
                                />
                              </button>
                            }
                          >
                            <ExcelSheet
                              data={this.state.excelDataSet}
                              name="UserData"
                            >
                              <ExcelColumn label="Username" value="userName" />
                              <ExcelColumn
                                label="First Name"
                                value="firstName"
                              />
                              <ExcelColumn label="Last Name" value="lastName" />
                              <ExcelColumn label="Email" value="email" />
                              <ExcelColumn label="Phone" value="phone" />
                              <ExcelColumn label="Admin" value="admin" />
                              <ExcelColumn label="Verified" value="verified" />
                              <ExcelColumn
                                label="Company Name"
                                value="company"
                              />
                              <ExcelColumn label="Created" value="created" />
                              <ExcelColumn label="Updated" value="updated" />
                            </ExcelSheet>
                          </ExcelFile>
                          {/* </td> */}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.filteredUser.map((user) => (
                        <tr key={user.username}>
                          <td
                            style={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {user.username}
                          </td>
                          <td
                            style={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {user.firstName} {user.lastName}
                          </td>
                          <td
                            style={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {user.emailAddress}
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              defaultChecked={user.subadmin}
                              // disabled={user.subadmin ? true : false}
                              onChange={(e) => this.manageSub(e, user.username)}
                            />
                          </td>
                          <td>
                            <UserInfoModal
                              username={user.username}
                              firstName={user.firstName}
                              lastName={user.lastName}
                              emailAddress={user.emailAddress}
                              phoneNumber={user.phoneNumber}
                              admin={user.subadmin}
                              verified={user.verified}
                              created_at={user.created_at}
                              updated_at={user.updated_at}
                            />
                          </td>
                          <td>
                            <Link to={`/unregUser/${user.username}`}>
                              <FontAwesomeIcon
                                icon="trash-alt"
                                size="lg"
                                color="red"
                                title="Delete User"
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ScrollView>
              </div>
            )}
          </div>
        </div>
        {/* <BackGroundRectangle />*/}
      </React.Fragment>
    );
  }
}

export default ManageUsers;

class UserInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          onClick={this.handleShow}
          className="btn btn-outline-light"
          style={{ padding: "0", marginTop: "-5px" }}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="User Information"
          />
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              User Information:
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">User Name: {this.props.username}</p>

            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">
              Name: {this.props.firstName} {this.props.lastName}
            </p>

            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.emailAddress}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.phoneNumber}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">{this.props.admin && "Admin: Yes"}</p>
            <p className="modalTextSmall">{!this.props.admin && "Admin: No"}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">
              {this.props.verified && "Verified: Yes"}
            </p>
            <p className="modalTextSmall">
              {!this.props.verified && "Verified: No"}
            </p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">Created: {this.props.created_at}</p>
            <hr className="modalLine" style={{ backgroundColor: "tomato" }} />
            <p className="modalTextSmall">Updated: {this.props.updated_at}</p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
