import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import Form from "./common/form";
import {
  getSystem,
  getSystemSignals,
  setSystemSignals,
  getSystemIDfromHandle,
  initSystemUpdate,
  getSystemLatestData,
  ws_getSystemLatestData,
  findSystemBySerialNumber,
} from "../services/systemService";
import { getUser } from "../services/userService";
import {
  emaLogin,
  getEmaDevices,
  getEmaDeviceInfo,
  emaNotify,
  emaConnectionStatus,
} from "../services/emaService";
import { setJwt, setEmaToken, setEmaApiKey } from "../services/httpService";
import BackGroundRectangle from "../components/backgroundRect";
import emailjs from "emailjs-com";
import {
  webSocketMode,
  WEATHER_INFO_URL,
  WEATHER_API_KEY,
  WEATHER_4G_OPEN,
  WEATHER_4G_URL,
} from "../config.json"; //0: http; 1:websocket

class SignalsForm extends Form {
  state = {
    systemID: "",
    systemName: "",
    data: {
      performFunction: "0",
      WiFi_SSID: "SimplGlobal",
      WiFi_PSWD: "SimplBox",
      parameterThree: "",
    },

    optionsFunction: [
      {
        _id: "0",
        name: "Do not perform any function",
      },
      { _id: "1", name: "Reset System" },
      { _id: "2", name: "Factory Reset System Hub" },
      { _id: "3", name: "Update System Hub FW" },
      { _id: "20", name: "Update Subsystem FW" },
      { _id: "22", name: "Mesh ID: Set to Default" },
      { _id: "4", name: "Mesh ID: Re-init to Random Number" },
      { _id: "21", name: "Update WiFi SSID & PSWD" },
      { _id: "6", name: "Force to 4G for Production Testing Only...." },
      { _id: "5", name: "Add/update the system tariff" },
      { _id: "43", name: "Reset Peak Power to Zero" },
      { _id: "7", name: "Update System Basics" },
      { _id: "8", name: "Update System Limits" },
      { _id: "9", name: "Update System Controls" },
      { _id: "10", name: "Refresh Real-time Clock" },

      //{ _id: "23", name: "Switch SBUs to WIFI" },
      //{ _id: "24", name: "Switch SBUs to Normal Operations" },
      //{ _id: "10", name: "Refresh Real-time Clock" },
      //{ _id: "11", name: "Report Settings" },
      //{ _id: "12", name: "SimplMeter: RS485 Discovery" },
      //{ _id: "18", name: "Deinit 4G Mgmt" },
      //{ _id: "19", name: "Download Unreported Daily to BE" },
      //{ _id: "51", name: "emaLink: Reset ESP" },
      //{ _id: "52", name: "emaLink: Turn off modem" },
      //{ _id: "38", name: "Reset a Single Device Thru Lora" },
      // { _id: "39", name: "Switch a single Sensor to WiFi Mode" },
      //{ _id: "40", name: "Switch all Sensors to WiFi Mode" },
      // { _id: "41", name: "Switch all Sensors to Lora Mode" },
      //{ _id: "42", name: "Add an Offset to a Lora Thermistor" },

      { _id: "49", name: "Download Slave FW 4G" },
      { _id: "93", name: "Change Weather Info API" },
      { _id: "94", name: "Power-On Reset 4G Modem" },
    ],
    errors: {},
    user: "unknown",
  };

  schema = {
    performFunction: Joi.string().label(
      "Perform this function on the entire system:"
    ),
    WiFi_SSID: Joi.string().label("Pass WiFi SSID"),
    WiFi_PSWD: Joi.string().required().min(8).label("Pass WiFi PSWD"),
    parameterThree: Joi.string().label("Pass optional parameter string #3"),
  };

  async componentDidMount() {
    clearInterval(this.timerID);
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const loggedInUser = (await getUser()).data.data;
    console.log("Logged In user in System Signals", loggedInUser);
    const userCompany = loggedInUser.company;
    console.log("User Company", userCompany);
    const userRole = this.props.match.params.userRole;
    console.log("User Role", userRole);
    this.setState({ loggedInUser, userCompany, userRole });

    const signalsFunction = this.props.match.params.signalsFunction;
    console.log("Signals Function", signalsFunction);
    const handleId = this.props.match.params.systemHandleID;
    console.log("Handle ID: ", handleId);
    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];

    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(handleId);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(handleId)).data.data[0];
      console.log("Http: Latest Data", response);
    }

    console.log("Latest Data", response);
    const selectedAP = response.data.deviceLedger.SSID;
    let selectedAPrssi = "";
    const accessPoints = response.data.accessPoints;
    console.log("Access Points:", accessPoints);
    let apNum = response.data.apNum;
    console.log("Number of APs: ", apNum);
    if (apNum > 5) apNum = 5;

    let i = 0;
    let apSSID = [];
    let apRSSI = [];
    for (i = 0; i < apNum; i++) {
      apSSID[i] = accessPoints[i].ap_ssid;
      apRSSI[i] = accessPoints[i].ap_rssi;
      if (selectedAP === apSSID[i]) {
        let signalStrength = apRSSI[i];
        if (signalStrength > -55) selectedAPrssi = "Excellent";
        else if (signalStrength > -75) selectedAPrssi = "Good";
        else if (signalStrength > -88) selectedAPrssi = "Weak";
        else selectedAPrssi = "Bad";
      }
      console.log("Access Point: ", apSSID[i]);
      console.log("Signal Strength: ", apRSSI[i]);
    }

    const systemOwner = system.systemPeople.owner[0];
    console.log("System Owner in Signals", systemOwner);
    let fourGSN = system.systemBasics.fourGserialNumber;

    if (fourGSN === null || fourGSN === undefined) fourGSN = "NA";

    const systemName = system.systemID.name;
    const interimSS = await getSystemSignals(handleId);
    console.log("InterimSS", interimSS);
    const systemSignals = interimSS.data.data.signals;

    // if (systemSignals) this.setState({ data: systemSignals });
    //console.log("System Signals: ", systemSignals);

    this.setState({ systemID, fourGSN });
    this.setState({
      systemName,
      systemOwner,
      accessPoints,
      selectedAP,
      selectedAPrssi,
      accessPoint: selectedAP,
    });

    const username = await getUser();
    if (username) {
      this.setState({ username: username.data.data.username });
    }
  }

  async emaLinkTask() {
    const emaJWT = await emaLogin();
    console.log("Ema Login:", emaJWT);

    setEmaToken(emaJWT);
    setEmaApiKey(emaJWT);
    const emaSerialNumber = this.state.fourGSN; //"ema-b2f3e0a0008b";
    if (emaSerialNumber === "NA")
      toast.error("EMA serial number is not assigned.");
    else console.log("4G Serial Number", emaSerialNumber);

    const emaDevices = await getEmaDevices();
    console.log("EMA Devices: ", emaDevices);

    try {
      const deviceInfo = await getEmaDeviceInfo(emaSerialNumber);
      console.log("EMA Device Info", deviceInfo);
      const emaStatus = (await emaConnectionStatus(emaSerialNumber)).data
        .connected;
      console.log("EMA Status", emaStatus);

      if (emaStatus) {
        //These two commands are the only two commands available for now
        let emaCommand = "";
        if (this.state.data.performFunction === "51") emaCommand = "_ResetESP_";
        else if (this.state.data.performFunction === "52")
          emaCommand = "_EMAPowerCycle_";
        console.log("EMA: ", emaSerialNumber, emaCommand);
        const emaBoardNotify = await emaNotify(emaSerialNumber, emaCommand);
        toast.success("Success: EMALink is connected.");
        console.log("EMA Notify", emaBoardNotify);
      } else toast.error("This EMA Modem is not connected.");
    } catch (ex) {
      if (ex.response && ex.response.status > 400) {
        toast.error("Invalid EMA serial number.");
      }
    }
  }
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  doSubmit = async () => {
    if (
      this.state.data.performFunction === "51" ||
      this.state.data.performFunction === "52"
    )
      this.emaLinkTask();
    ////////////////////////////////////////////////////////////////////////////////
    else if (this.state.data.performFunction === "21") {
      const systemID = this.state.systemID;
      console.log("System ID: ", systemID);
      const wifiPswd = this.state.passWord; //this.state.data.WiFi_PSWD;
      const wifiSsid = this.state.accessPoint; //this.state.data.WiFi_SSID;
      console.log("WifiSsid: ", wifiSsid);
      const wifiPswdLen = wifiPswd.length;
      const wifiSsidLen = wifiSsid.length;

      if (wifiPswdLen < 8)
        toast.error(
          "WiFi Password is too short.  Must be 8 or more characters"
        );
      else if (wifiPswdLen > 32 || wifiSsidLen > 32)
        toast.error(
          "WiFi Password or SSID is too long.  Must be no more than 32 characters"
        );
      else {
        // const systemFunction = this.props.match.params.signalsFunction;
        console.log("In System Signals Change SSID and PSWD");
        const slaveMAC = "unKnown";
        await setSystemSignals(
          this.state.systemID,
          this.state.data.performFunction,
          //this.state.data.WiFi_SSID,
          this.state.accessPoint,
          //this.state.data.WiFi_PSWD,
          this.state.passWord,
          this.state.data.parameterThree,
          slaveMAC,
          this.state.username
        );
        const systemFunction = this.props.match.params.signalsFunction;
        if (systemFunction !== "signalOnly") {
          console.log("System Function", systemFunction);
          await initSystemUpdate(this.props.match.params.systemHandleID);
        }
      }
    }
    ///////////////////////////////////////////////////////////////////////////////
    else if (
      this.state.data.performFunction === "38" ||
      this.state.data.performFunction === "39"
    ) {
      const systemFunction = this.props.match.params.signalsFunction;
      const slaveSN = this.state.slaveSN;
      console.log("Device Serial Number: ", slaveSN);
      const slaveUnit = (await findSystemBySerialNumber(slaveSN)).data.data
        .system_id;
      console.log("Slave Unit: ", slaveUnit);
      const slaveSystem = await getSystem(slaveUnit);
      const slaveMAC = slaveSystem.data.data[0].systemID.MAC;
      console.log("Slave System: ", slaveMAC);
      await setSystemSignals(
        this.state.systemID,
        this.state.data.performFunction,
        this.state.accessPoint,
        this.state.passWord,
        this.state.data.parameterThree,
        slaveMAC,
        this.state.username
      );

      if (systemFunction !== "signalOnly") {
        if (this.props.match.params.userRole === "companyAdmin") {
          console.log("System Function", systemFunction);
          console.log(
            "System Handle ID: ",
            this.props.match.params.systemHandleID
          );
          await initSystemUpdate(this.props.match.params.systemHandleID);
        }
      }
      this.sleep(5000); //Wait for reset to take effect for the loop running as slow as 15000 mseconds
      await setSystemSignals(
        this.state.systemID,
        "0",
        "",
        "",
        "",
        "FF:FF:FF:FF:FF:FF" /*slaveMAC*/,
        this.state.username
      );
      await initSystemUpdate(this.props.match.params.systemHandleID);
    } else if (this.state.data.performFunction === "42") {
      const systemFunction = this.props.match.params.signalsFunction;
      const slaveSN = this.state.slaveSN;
      console.log("Device Serial Number: ", slaveSN);
      const slaveUnit = (await findSystemBySerialNumber(slaveSN)).data.data
        .system_id;
      console.log("Slave Unit: ", slaveUnit);
      const slaveSystem = await getSystem(slaveUnit);
      const slaveMAC = slaveSystem.data.data[0].systemID.MAC;
      console.log("Slave System: ", slaveMAC);
      await setSystemSignals(
        this.state.systemID,
        this.state.data.performFunction,
        this.state.accessPoint,
        this.state.passWord,
        this.state.rthOffset,
        slaveMAC,
        this.state.username
      );

      if (systemFunction !== "signalOnly") {
        if (this.props.match.params.userRole === "companyAdmin") {
          console.log("System Function", systemFunction);
          console.log(
            "System Handle ID: ",
            this.props.match.params.systemHandleID
          );
          await initSystemUpdate(this.props.match.params.systemHandleID);
        }
      }
      this.sleep(5000); //Wait for reset to take effect for the loop running as slow as 15000 mseconds
      await setSystemSignals(
        this.state.systemID,
        "0",
        "",
        "",
        "",
        "FF:FF:FF:FF:FF:FF" /*slaveMAC*/,
        this.state.username
      );
      await initSystemUpdate(this.props.match.params.systemHandleID);
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////
    else if (this.state.data.performFunction === "93") {
      const systemFunction = this.props.match.params.signalsFunction;
      await setSystemSignals(
        this.state.systemID,
        this.state.data.performFunction,
        WEATHER_INFO_URL,
        WEATHER_4G_OPEN,
        WEATHER_4G_URL,
        WEATHER_API_KEY,
        /*"http://api.openweathermap.org/data/2.5/weather?q=",
        "api.openweathermap.org",
        "/data/2.5/weather?q=",
        "&appid=75d370f627f7c8df74a1fa7a9839e5ea",*/
        this.state.username
      );
      if (systemFunction !== "signalOnly") {
        if (this.props.match.params.userRole === "companyAdmin") {
          console.log("System Function", systemFunction);
          console.log(
            "System Handle ID: ",
            this.props.match.params.systemHandleID
          );
          await initSystemUpdate(this.props.match.params.systemHandleID);
        }
      }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////
    else {
      const systemFunction = this.props.match.params.signalsFunction;
      const slaveMAC = "unKnown";
      const signalsResponse = await setSystemSignals(
        this.state.systemID,
        this.state.data.performFunction,
        //this.state.data.WiFi_SSID,
        this.state.accessPoint,
        //this.state.data.WiFi_PSWD,
        this.state.passWord,
        this.state.data.parameterThree,
        slaveMAC,
        this.state.username
      );
      console.log("System Signals Response: ", signalsResponse);
      if (systemFunction !== "signalOnly") {
        if (this.props.match.params.userRole === "companyAdmin") {
          console.log("System Function", systemFunction);
          console.log(
            "System Handle ID: ",
            this.props.match.params.systemHandleID
          );
          await initSystemUpdate(this.props.match.params.systemHandleID);
        } else {
          console.log("System Function", systemFunction);
          toast.error(
            "You must be your company's Admin to make these changes..."
          );
        }
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////
    this.props.history.goBack();
    /*this.props.history.push(`/Companies/systems/${this.state.userCompany}`);*/
  };

  handleAPChange = ({ currentTarget: input }) => {
    console.log("Input Value", input);
    const accessPoint = input.value;
    console.log("Selected Access Point", accessPoint);
    this.setState({ accessPoint });
  };

  handlePassword = ({ currentTarget: input }) => {
    const passWord = input.value;
    console.log("Selected passWord", passWord);
    this.setState({ passWord });
  };
  handleSN = ({ currentTarget: input }) => {
    const slaveSN = input.value;
    this.setState({ slaveSN });
  };
  handleOffset = ({ currentTarget: input }) => {
    const rthOffset = input.value;
    this.setState({ rthOffset });
  };
  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150",
            zIndex: 1,
          }}
        >
          {this.props.match.params.signalsFunction === "signalOnly" && (
            <p className="basicParagraph">
              Configure all update parameters for:
            </p>
          )}
          {this.props.match.params.signalsFunction === "initiateUpdate" && (
            <p className="basicParagraph">
              Before you initiate an update, confirm all parameters for:
            </p>
          )}

          <p className="basicParagraph">{this.state.systemName}</p>

          <form onSubmit={this.handleSubmit}>
            {this.renderSelect(
              "performFunction",
              "Perform this function on the system:",
              this.state.optionsFunction
            )}
            {/*8888888888888888888888888888888888888888888888888888888888888888888*/}
            {this.state.data.performFunction === "38" && (
              <div
                style={{
                  width: "350",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "50px",
                  marginBottom: "50",
                }}
              >
                <p className="basicParagraph">
                  Serial Number for the Lora Device:
                </p>
                <input
                  className="form-control2"
                  value={this.state.slaveSN}
                  onChange={this.handleSN}
                  id="macAddress"
                  type="text"
                />
                <div>{this.renderButton("Reset Lora Device")}</div>
              </div>
            )}
            {/*8888888888888888888888888888888888888888888888888888888888888888888*/}
            {this.state.data.performFunction === "39" && (
              <div
                style={{
                  width: "350",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "50px",
                  marginBottom: "50",
                }}
              >
                <p className="basicParagraph">
                  Serial Number for the Lora Device:
                </p>
                <input
                  className="form-control2"
                  value={this.state.slaveSN}
                  onChange={this.handleSN}
                  id="macAddress"
                  type="text"
                />
                <div>{this.renderButton("Switch to Wi-Fi")}</div>
              </div>
            )}
            {/**88888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
            {this.state.data.performFunction === "42" && (
              <div
                style={{
                  width: "350",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "50px",
                  marginBottom: "50",
                }}
              >
                <p className="basicParagraph">
                  Serial Number for the Lora Device:
                </p>
                <input
                  className="form-control2"
                  value={this.state.slaveSN}
                  onChange={this.handleSN}
                  id="macAddress"
                  type="text"
                />
                <p className="basicParagraph">Enter the Offset Value in *F:</p>
                <input
                  className="form-control2"
                  value={this.state.rthOffset}
                  onChange={this.handleOffset}
                  id="macAddress"
                  type="text"
                />
                <div>{this.renderButton("Add Offset to Lora Rth")}</div>
              </div>
            )}
            {/**88888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}

            {/**88888888888888888888888888888888888888888888888888888888888888888888888888888888888*/}
            {this.state.data.performFunction === "21" && (
              <div
                style={{
                  width: "350",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "50px",
                  marginBottom: "50",
                }}
              >
                <p className="basicParagraph">Selected Access Point:</p>
                <p
                  className="basicParagraph"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  {this.state.selectedAP}: {this.state.selectedAPrssi} Signal
                </p>
                <label className="basicParagraph" htmlFor="">
                  Available Access Points (SSID):
                </label>
                <label className="basicParagraph" htmlFor="">
                  In order of descending signal strength
                </label>
                <div>
                  <select
                    className="form-control2"
                    onChange={this.handleAPChange}
                    value={this.state.accessPoint}
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {this.state.accessPoints.map((ap) => (
                      <option value={ap.id}>{ap.ap_ssid}</option>
                    ))}
                  </select>
                </div>
                <label className="basicParagraph" htmlFor="">
                  Access Point Password (8 to 32 characters)
                </label>
                <input
                  className="form-control2"
                  value={this.state.password}
                  onChange={this.handlePassword}
                  id="pswd"
                  type="text"
                />
                <div>{this.renderButton("Update Access Point")}</div>
              </div>
            )}

            {this.props.match.params.signalsFunction === "signalOnly" &&
              this.renderButton("Save Parameters")}
            {this.props.match.params.signalsFunction === "initiateUpdate" &&
              this.renderButton("Initiate Update")}
          </form>
          <h6> </h6>
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default SignalsForm;
