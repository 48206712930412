import React, { Component } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";

class AdminMenu extends React.Component {
  render() {
    const anchorEl = this.props.anchorState;

    return (
      <div>
        <button
          aria-owns={anchorEl ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.props.onClickingMenu}
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
        >
          <FontAwesomeIcon icon="bars" size="lg" color="orange" title="Menu" />
        </button>
        <Menu
          style={{
            borderWidth: "0px",
            marginRight: "30px",
            transform: "translate(-5%, 5%)",
          }}
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.props.onClickingMenu(null)}
        >
          {" "}
          <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingListView}
          >
            Systems List View
          </MenuItem>
          <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingBoard}
          >
            Register a new system
          </MenuItem>
          <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingUninstallBoard}
          >
            Uninstall a system
          </MenuItem>
          {/* <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingProduct}
          >
            Register a new Product
          </MenuItem> */}
          {/* <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingUser}
          >
            Add a registered User
          </MenuItem> */}
          <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingFleet}
          >
            Fleet Control
          </MenuItem>
          {/* <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingAccess}
          >
            User Access Control
          </MenuItem> */}
          {/* <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingHistory}
          >
            User History
          </MenuItem> */}
          {/**
           * Option to show filters
           */}
          <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingFilter}
          >
            Filter Systems
          </MenuItem>
          <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onchoosingManage}
          >
            Manage Users
          </MenuItem>
          <MenuItem
            style={{
              fontSize: "10px",
              margin: "2px",
              padding: "2px",
              fontWeight: "bold",
            }}
            onClick={this.props.onChoosingEscape}
          >
            None of the above
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default AdminMenu;
