import React, { Component } from "react";
import { toast } from "react-toastify";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "./common/form";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import GitInfo from "react-git-info/macro";

class AccountInfo extends Component {
  state = {
    user: {},
    adminUsername: "",
    mapState: true,
  };

  async componentDidMount() {
    try {
      const jwt = localStorage.getItem("token");
      setJwt(jwt);

      console.log("Application Name: ", process.env.REACT_APP_NAME);
      console.log("SimplWeb Version: ", process.env.REACT_APP_VERSION);

      const gitInfo = GitInfo();
      console.log(gitInfo.branch);
      console.log(gitInfo.tags);
      console.log(gitInfo.commit.date);
      console.log(gitInfo.commit.hash);
      console.log(gitInfo.commit.message);
      console.log(gitInfo.commit.shortHash);

      const mapBoolean = JSON.parse(localStorage.getItem("mapBoolean"));
      console.log("Map Boolean", mapBoolean);
      const user = await getUser();
      console.log("User", user);

      this.setState({ user: user.data.data });
      this.setState({ mapState: mapBoolean });
      this.setState({ appName: process.env.REACT_APP_NAME });
      this.setState({ appVersion: process.env.REACT_APP_VERSION });
      this.setState({
        gitBranch: gitInfo.branch,
        gitTime: gitInfo.commit.date,
        gitHash: gitInfo.commit.shortHash,
      });
    } catch (e) {
      window.location.reload();
    }
  }
  useMap = (e) => {
    e.preventDefault();
    let mapState = this.state.mapState;
    mapState = !mapState;
    const mapBoolean = mapState;
    localStorage.setItem("mapBoolean", JSON.stringify(mapBoolean));
    console.log("Map State", mapState);
    this.setState({ mapState });
  };
  render() {
    console.log("User Info", this.state.user);

    return (
      <React.Fragment>
        <div
          style={{
            width: "350",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          {!this.state.user.company && (
            <div className="basicParagraph" style={{ textAlign: "center" }}>
              <p
                style={{ fontSize: "25px", fontWeight: "600", color: "tomato" }}
              >
                Congratulations!
              </p>
              <p style={{ fontSize: "15px", fontWeight: "600" }}>
                You are now registered.
              </p>
              <p>
                However, currently you are not registered with a specific
                company or system.
              </p>
              {/*<p>
                In addition, you are able to press on any of the demo systems
                below to see a system in action.
              </p>*/}
              <p>
                To complete your registration, please contact the company that
                installed your system and provide your username from the table
                below.
              </p>
              <p style={{ marginBottom: "40px" }}>
                To sign up as a new company ADMIN contact info@simplglobal.com
                and be sure to provide your username.
              </p>
            </div>
          )}
          {/*<table className="table">
            <thead>
              <td></td>
              <td>SimplBox</td>
              <td>SimplMeter</td>
            </thead>
            <tr>
              <th style={{ textAlign: "left" }}>Live Demo Systems:</th>
              <td>
                <Link to={`/systems/dashboard/45/Simpl%20Global%20Inc.`}>
                  <FontAwesomeIcon
                    icon="desktop"
                    size="lg"
                    color="magenta"
                    title="SimplBox Live Demo System"
                  />
                </Link>
              </td>
              <td>
                <Link to={`/systems/dashboard/68/Simpl%20Global%20Inc.`}>
                  <FontAwesomeIcon
                    icon="desktop"
                    size="lg"
                    color="purple"
                    title="SimplMeter Live Demo System"
                  />
                </Link>
              </td>
            </tr>
            </table>*/}
          <table className="table">
            <thead>
              <td>
                <Link to="/editUser">
                  <FontAwesomeIcon
                    icon="address-card"
                    size="lg"
                    color="Green"
                    title="Edit Contact Info"
                  />
                </Link>
              </td>
              <td>
                <Link to="/editPswd">
                  <FontAwesomeIcon
                    icon="key"
                    size="lg"
                    color="dodgerblue"
                    title="Update Password"
                  />
                </Link>
              </td>
              <td>
                <Link to={`/unregUser/${this.state.user.username}`}>
                  <FontAwesomeIcon
                    icon="underline"
                    size="lg"
                    color="red"
                    title="UnRegister"
                  />
                </Link>
              </td>
              <td>
                <Link to={`/s2p2/${this.state.user.username}`}>
                  <FontAwesomeIcon
                    icon="calculator"
                    size="lg"
                    color="green"
                    title="Sizing | Pricing | Payback"
                  />
                </Link>
              </td>
              <td>
                <button
                  onClick={this.useMap}
                  className="btn btn-outline-light"
                  style={{ padding: "0px", borderWidth: "0px" }}
                >
                  <FontAwesomeIcon
                    icon="globe-americas"
                    size="lg"
                    color="orange"
                    title="Toggle Map/List"
                  />
                </button>
              </td>
              <td>
                <Link to={`/logout`}>
                  <FontAwesomeIcon
                    icon="sign-out-alt"
                    size="lg"
                    color="tomato"
                    title="Log out"
                  />
                </Link>
              </td>
              <td>
                <AppVersionModal
                  appName={this.state.appName}
                  appVersion={this.state.appVersion}
                  gitBranch={this.state.gitBranch}
                  gitTime={this.state.gitTime}
                  gitHash={this.state.gitHash}
                />
              </td>
            </thead>
          </table>
          <table className="table">
            <tbody>
              <tr>
                <td> Username:</td>
                <td align={"center"}>{this.state.user.username}</td>
              </tr>
              <tr>
                <td> Name:</td>
                <td align={"center"}>
                  {this.state.user.firstName} {this.state.user.lastName}
                </td>
                <td align={"center"} />
              </tr>
              <tr>
                <td> Company Name:</td>
                <td align={"center"}>{this.state.user.company}</td>
              </tr>
              <tr>
                <td>Email Address:</td>

                <td align={"center"}>{this.state.user.emailAddress}</td>
                <td align={"center"} />
              </tr>
              <tr>
                <td>Phone Number:</td>

                <td align={"center"}>{this.state.user.phoneNumber}</td>
                <td align={"center"} />
              </tr>
              <tr>
                <td>Admin (Yes/No):</td>
                {this.state.user.admin && <td align={"center"}>Yes</td>}
                {!this.state.user.admin && <td align={"center"}>No</td>}
                <td align={"center"} />
              </tr>
              <tr>
                <td>Map Enabled (Yes/No):</td>
                {this.state.mapState && <td align={"center"}>Yes</td>}
                {!this.state.mapState && <td align={"center"}>No</td>}
                <td align={"center"} />
              </tr>
            </tbody>
          </table>
        </div>{" "}
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default AccountInfo;

class AppVersionModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="lg"
            color="dodgerblue"
            title="App Version"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.appName}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.appVersion}
            </p>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.gitBranch}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.gitTime}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.gitHash}
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
