import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getCompanySystems } from "../services/companyService";
import {
  getUser,
  setUserFilters,
  getUserFilters,
} from "../services/userService";
import {
  getSystems,
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemBasics,
  checkConnectionInfo,
  getSystemInfo,
} from "../services/systemService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAlphaDown,
  faSortAlphaUpAlt,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { setJwt } from "../services/httpService";
import { masterCompany } from "../config.json";
import BackGroundRectangle from "../components/backgroundRect";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "bootstrap/dist/css/bootstrap.min.css";
import jwtDecode from "jwt-decode";
import AdminMenu from "./adminMenu";

import { RaleysImage, masterCompanyLogo } from "../config.json";

import { webSocketMode } from "../config.json"; //0: http; 1:websocket

import { ScrollView } from "@cantonjs/react-scroll-view";

class CompanySystems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      allSystems: [],
      companySystems: [],
      activeCompanySystems: [],
      userCompany: "",
      systems: [],
      isActive: true,
      response: [],
      filteredSystems: [],
      vicePresidents: [],
      regionalManagers: [],
      regionalStaffsI: [],
      regionalStaffsII: [],
      costCenters: [],
      regions: [],
      filters: [
        "Vice President",
        "Regional Manager",
        "Staff I",
        "Staff II",
        "Cost Center",
        "Region",
      ],
      selectedFilter: "Filters",
      showFilter: false,
      auxFilteredSystems: [],
    };

    this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    const decodedJWT = jwtDecode(jwt).exp * 1000;
    console.log("Decoded JWT", decodedJWT);
    const date = Date.now();
    console.log("EPOCH Now:", date);
    let mustLoginAgain = this.state.mustLoginAgain;
    if (date >= decodedJWT) {
      mustLoginAgain = true;
      console.log("Go to Login Page");
    } else {
      mustLoginAgain = false;
    }

    setJwt(jwt);
    const userCompany = (await getUser()).data.data.company;
    const systemCompany = this.props.match.params.id;
    console.log("System Company: ", systemCompany);
    this.setState({ systemCompany });
    const companySystems = (await getCompanySystems(this.props.match.params.id))
      .data.data;

    console.log("Company Systems before Filter", companySystems);
    const activeCompanySystems = companySystems.filter(
      (thisSystem) => thisSystem.active === this.state.isActive
    );
    /////////////////////////////////////////////////////////
    let systems = companySystems;
    if (this.state.isActive) systems = activeCompanySystems;
    console.log("Systems in Company Systems:", systems);
    ////////////////////////////////////////////////////////////
    const allSystems = await getSystems();
    console.log("User Company", userCompany);
    console.log("Company Systems", companySystems);
    console.log("Active Systems", activeCompanySystems);

    const firstCompanySystemHandle = activeCompanySystems[0].systemID.id;
    console.log("First System Handle ID: ", firstCompanySystemHandle);

    const thisCompany = masterCompany;
    console.log("Master Company", thisCompany);

    /****************** For search bar *************/

    let filteredSystems = systems;

    // this.setState({filteredSystems});

    // console.log("filtered systems set", this.state.filteredSystems);
    console.log("systems:", systems);
    console.log("filtered systems", filteredSystems);

    if (filteredSystems)
      filteredSystems = this.sortSystems(filteredSystems, true);
    this.setState({
      filteredSystems: filteredSystems.sortedSystems,
      matchSorted: false,
    });

    console.log("Filetered systems", this.state.filteredSystems);
    /**********************************************/
    this.setState({
      systems,
      companySystems,
      thisCompany,
      firstCompanySystemHandle,
    });
    this.setState({ activeCompanySystems, systemCompany });
    this.setState({ allSystems: allSystems.data.data });
    this.setState({ userCompany, mustLoginAgain });
    this.setState({ isActive: true });
    this.setState({ firstLoading: true });
    this.timerID = setInterval(() => this.tick(), 1000);

    /*********** Getting the info from systemAddress to filter  ***********/
    const vicePresidents = [];
    const regionalManagers = [];
    const regionalStaffsI = [];
    const regionalStaffsII = [];
    const costCenters = [];
    const regions = [];

    companySystems.forEach((system) => {
      if (system.systemAddress.vicePresident) {
        let repeatedName = vicePresidents.find((vp) => {
          return vp === system.systemAddress.vicePresident;
        });
        if (!repeatedName)
          vicePresidents.push(system.systemAddress.vicePresident);
      }
      if (system.systemAddress.regionalManager) {
        let repeatedName = regionalManagers.find((rm) => {
          return rm === system.systemAddress.regionalManager;
        });
        if (!repeatedName)
          regionalManagers.push(system.systemAddress.regionalManager);
      }
      if (system.systemAddress.regionalStaffI) {
        let repeatedName = regionalStaffsI.find((staff) => {
          return staff === system.systemAddress.regionalStaffI;
        });
        if (!repeatedName)
          regionalStaffsI.push(system.systemAddress.regionalStaffI);
      }
      if (system.systemAddress.regionalStaffII) {
        let repeatedName = regionalStaffsII.find((staff) => {
          return staff === system.systemAddress.regionalStaffII;
        });
        if (!repeatedName)
          regionalStaffsII.push(system.systemAddress.regionalStaffII);
      }
      if (system.systemAddress.costCenter) {
        let repeatedName = costCenters.find((center) => {
          return center === system.systemAddress.costCenter;
        });
        if (!repeatedName) costCenters.push(system.systemAddress.costCenter);
      }
      if (system.systemAddress.region) {
        let repeatedName = regions.find((region) => {
          return region === system.systemAddress.region;
        });
        if (!repeatedName) regions.push(system.systemAddress.region);
      }
    });
    console.log("Vice presidests array:", vicePresidents);
    console.log("regional managers array:", regionalManagers);
    console.log("regional staff 1 array:", regionalStaffsI);
    console.log("regional staff 2 array:", regionalStaffsII);
    console.log("cost centers array:", costCenters);
    console.log("Regions array:", regions);

    this.setState({
      vicePresidents,
      regionalManagers,
      regionalStaffsI,
      regionalStaffsII,
      costCenters,
      regions,
    });

    /*** Getting user filter info ***/
    const filters = (await getUserFilters()).data.message.filters;
    console.log("filters", filters);

    if (filters) {
      if (filters.active) {
        if (filters.type) {
          this.setState({ selectedFilter: filters.type });

          if (filters.value) {
            this.setState({ value: filters.value });
            if (filters.type === "Vice President") {
              this.handleVicePresidentFilter(null, filters.value);
            } else if (filters.type === "Regional Manager") {
              this.handleManagerFilter(null, filters.value);
            } else if (filters.type === "Staff I") {
              this.handleStaffIFilter(null, filters.value);
            } else if (filters.type === "Staff II") {
              this.handleStaffIIFilter(null, filters.value);
            } else if (filters.type === "Cost Center") {
              this.handleCenterFilter(null, filters.value);
            } else if (filters.type === "Region") {
              this.handleRegionFilter(null, filters.value);
            }
          }
        }
        this.setState({ showFilter: true });
      }
    }
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });
    this.getSystemData();
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  handleListViewMenu = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/systems/listView/0/${this.state.systemCompany}`);
  };
  handleBoardMenu = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/systems/newBoard/${this.state.systemCompany}`);
  };
  handleUninstallBoardMenu = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/systems/uninstallBoard`);
  };
  handleProductMenu = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/systems/newProduct`);
  };
  handleUserMenu = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/users/updateCompany`);
  };
  handleFleet = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/Companies/fleetInfo/${this.state.systemCompany}`);
  };
  handleEscapeMenu = () => {
    console.log("Escape");
    this.setState({ anchorEl: null });
  };
  handleClickMenu = (event) => {
    console.log("Menu");
    this.setState({ anchorEl: event ? event.currentTarget : null });
  };
  handleAcessControl = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/users/userAccessControl`);
  };
  handleHistoryControl = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/users/userHistory/${this.state.systemCompany}`);
  };
  handleManageUsers = () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/users/manageUsers/${this.state.systemCompany}`);
  };

  /************** Function for filters in admin menu ***********************/
  /**
   * Function for handle filters in admin menu
   */
  handleFilterControl = async () => {
    this.setState({ anchorEl: null });
    if (this.state.showFilter) {
      this.setState({
        showFilter: false,
        value: null,
        selectedFilter: "Filters",
        filteredSystems: this.state.companySystems,
      });
      await setUserFilters(false, null, null);
    } else this.setState({ showFilter: true });
  };
  /*************************************************************************/

  async getSystemData() {
    console.log("Start getSystemData() function");

    const companySystems = (await getCompanySystems(this.props.match.params.id))
      .data.data;
    const activeCompanySystems = companySystems.filter(
      (thisSystem) => thisSystem.active === this.state.isActive
    );

    let systems = companySystems;
    if (this.state.isActive) systems = activeCompanySystems;
    //else systems = companySystems;
    const systemCount = systems.length;
    let i = 0;
    let response = [0];
    let systemTimeZone = [0];
    let systemTime = [0];
    let dataTimeInvalid = [0];
    let systemBasics = [0];
    let newSystem = [0];
    let iPowerGenerated = 0;
    let iPowerGrid = 0;
    let iPowerUsed = 0;
    let powerGenerated = 0;
    let powerGrid = 0;
    let powerUsed = 0;
    let wtfSystems = [0];
    //let systemConfiguration = 0;
    let sysConfig = [0];
    let circuitNameMeter1 = 0;
    console.log("System Count", systemCount);
    for (i = 0; i < systemCount; i++) {
      //response[i] = await getSystemLatestData(systems[i].systemID.id);
      console.log("System Handle ID:", systems[i].systemID.id);
      if (webSocketMode) {
        response[i] = ws_getSystemLatestData(systems[i].systemID.id);
      } else {
        response[i] = (
          await getSystemLatestData(systems[i].systemID.id)
        ).data.data[0];
      }

      systemBasics[i] = await getSystemBasics(systems[i].systemID.id);
      console.log("Response:", i, response[i]);
      circuitNameMeter1 = systemBasics[i].data.data.basics.circuitNameMeter1;
      sysConfig[i] = systemBasics[i].data.data.basics.systemConfiguration;
      console.log("System Configuration", sysConfig[i]);
      if (response[i] !== undefined) {
        if (response[i].data.deviceLedger !== undefined) {
          systemTimeZone[i] = parseInt(
            response[i].data.deviceLedger.realTime.TimeZone
          );
        }

        if (response[i].data.deviceLedger !== undefined) {
          systemTime[i] = parseInt(
            response[i].data.deviceLedger.realTime.SystemLocalTimeSeconds
          );
        }

        const myDataTimeInvalid = (
          await checkConnectionInfo(systems[i].systemID.id)
        ).data.data.Connection;

        if (myDataTimeInvalid === null) {
          dataTimeInvalid[i] = 2;
        } else {
          dataTimeInvalid[i] = myDataTimeInvalid;
        }

        if (
          dataTimeInvalid[i] === 0 &&
          response[i].data.deviceLedger.acuvim !== undefined
        ) {
          if (sysConfig[i] === "1" || sysConfig[i] === "2") {
            iPowerGrid = response[i].data.deviceLedger.acuvim.acuvimPower0;
            if (iPowerGrid < 0 || iPowerGrid > 10000000) iPowerGrid = 0;
            powerGrid += iPowerGrid;
          }

          if (sysConfig[i] === "2") {
            iPowerGenerated = response[i].data.deviceLedger.acuvim.acuvimPower1;
            if (iPowerGenerated < 1000 || iPowerGenerated > 10000000)
              iPowerGenerated = 0;
            powerGenerated += iPowerGenerated;
            if (circuitNameMeter1 === "0") {
              iPowerGrid = response[i].data.deviceLedger.acuvim.acuvimPower0;
              if (iPowerGrid < 0 || iPowerGrid > 10000000) iPowerGrid = 0;
              powerGrid += iPowerGrid;
              powerUsed = powerGrid + powerGenerated;
            } else if (circuitNameMeter1 === "1") {
              iPowerUsed = response[i].data.deviceLedger.acuvim.acuvimPower0;
              if (iPowerUsed < 0 || iPowerUsed > 10000000) iPowerUsed = 0;
              powerUsed += iPowerUsed;
              powerGrid = powerUsed - powerGenerated;
            } else {
              powerUsed = 0;
              powerGrid = 0;
            }
          }
        }
        console.log("-------------------------");
        console.log("DATA TIME INVALID");
        console.log(myDataTimeInvalid);
        console.log("---------------------- ---");

        console.log("Data Time Invalid", dataTimeInvalid[i]);
        newSystem[i] = Object.assign(systems[i], {
          systemConnection: dataTimeInvalid[i],
        });
        systems[i] = newSystem[i];
        console.log("New System", systems[i]);
      }
      clearInterval(this.timerID);
      this.timerID = setInterval(() => this.tick(), 30000);
    }
    /****************** For search bar ***********/

    // let filteredSystems = systems;

    // this.setState({filteredSystems});

    /*********************************************/
    powerGenerated = (powerGenerated / 1000000).toFixed(2);
    powerGrid = (powerGrid / 1000000).toFixed(2);
    powerUsed = (powerUsed / 1000000).toFixed(2);
    console.log("End getSystemData() function");
    this.setState({
      systems,
      systemCount,
      powerGenerated,
      powerGrid,
      powerUsed,
    });
    this.setState({ firstLoading: false });
  }

  showAll = (e) => {
    e.preventDefault();
    let whatState = this.state.isActive;
    whatState = !whatState;
    this.setState({ isActive: whatState });
  };

  handleRowClick = (
    systemConfig,
    handleID,
    companyName,
    systemSN,
    systemName,
    // userRole,
    action
  ) => {
    if (action === "listview") {
      this.props.history.push(
        `/systems/listview/${handleID}/${companyName}/companyAdmin`
      );
    } else if (action === "search") {
      this.props.history.push(
        `/systems/dashboardWideSlaves/${handleID}/${companyName}`
      );
    } else {
      if (systemConfig === "8")
        this.props.history.push(
          `/systems/simplNodesVu/${handleID}/${companyName}/${systemSN}/${systemName}/companyAdmin`
        );
      else
        this.props.history.push(
          `/systems/simplVu/${handleID}/${companyName}/${systemSN}/${systemName}/companyAdmin`
        );
    }
  };

  /************************** Search bar, filetrs and sorting functions **********************/

  handleSearch = (e) => {
    e.preventDefault();

    let filteredSystems = [];

    if (!this.state.showFilter || this.state.auxFilteredSystems.length === 0) {
      this.state.systems.forEach((system) => {
        if (
          system.systemID.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          filteredSystems.push(system);
        }
      });
    } else {
      this.state.auxFilteredSystems.forEach((system) => {
        if (
          system.systemID.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
          filteredSystems.push(system);
        console.log(
          "value and system name",
          e.target.value.toLowerCase(),
          system.systemID.name
        );
      });
    }

    const sortedSystems = this.sortSystems(filteredSystems);

    filteredSystems = sortedSystems.sortedSystems;
    const matchSorted = sortedSystems.matchSorted;

    if (matchSorted)
      filteredSystems = this.sortSystems(filteredSystems).sortedSystems;

    this.setState({ filteredSystems, matchSorted: false });
  };

  /**
   * Filter by Vice President
   * @param {event} e
   */
  handleVicePresidentFilter = async (e, opValue) => {
    let filteredSystems = [];
    let value;
    if (e) {
      e.preventDefault();
      value = e.target.value;
    } else {
      value = opValue;
    }

    this.state.companySystems.forEach((system) => {
      if (system.systemAddress.vicePresident === value)
        filteredSystems.push(system);
    });

    console.log("Value:", value);

    if (value === "filter-vp") filteredSystems = this.state.companySystems;
    let auxFilteredSystems = filteredSystems;

    filteredSystems = this.sortSystems(filteredSystems).sortedSystems;
    this.setState({ filteredSystems, auxFilteredSystems });
    if (!opValue || value === "filter-vp") {
      await setUserFilters(true, this.state.selectedFilter, value);
      this.setState({ value: null });
    }
  };

  handleManagerFilter = async (e, opValue) => {
    let filteredSystems = [];
    let value;

    if (e) {
      e.preventDefault();
      value = e.target.value;
    } else {
      value = opValue;
    }

    this.state.companySystems.forEach((system) => {
      if (system.systemAddress.regionalManager === value)
        filteredSystems.push(system);
    });

    if (value === "filter-manager") filteredSystems = this.state.companySystems;
    let auxFilteredSystems = filteredSystems;

    filteredSystems = this.sortSystems(filteredSystems).sortedSystems;
    this.setState({ filteredSystems, auxFilteredSystems });
    if (!opValue || value === "filter-manager") {
      await setUserFilters(true, this.state.selectedFilter, value);
      this.setState({ value: null });
    }
  };

  handleStaffIFilter = async (e, opValue) => {
    let filteredSystems = [];
    let value;

    if (e) {
      e.preventDefault();
      value = e.target.value;
    } else {
      value = opValue;
    }
    this.state.companySystems.forEach((system) => {
      if (system.systemAddress.regionalStaffI === value)
        filteredSystems.push(system);
    });

    if (value === "filter-staffI") filteredSystems = this.state.companySystems;
    let auxFilteredSystems = filteredSystems;

    filteredSystems = this.sortSystems(filteredSystems).sortedSystems;
    this.setState({ filteredSystems, auxFilteredSystems });
    if (!opValue || value === "filter-staffI") {
      await setUserFilters(true, this.state.selectedFilter, value);
      this.setState({ value: null });
    }
  };

  handleStaffIIFilter = async (e, opValue) => {
    let filteredSystems = [];
    let value;

    if (e) {
      e.preventDefault();
      value = e.target.value;
    } else {
      value = opValue;
    }

    this.state.companySystems.forEach((system) => {
      if (system.systemAddress.regionalStaffII === value)
        filteredSystems.push(system);
    });

    if (value === "filter-staffII") filteredSystems = this.state.companySystems;
    let auxFilteredSystems = filteredSystems;

    filteredSystems = this.sortSystems(filteredSystems).sortedSystems;
    this.setState({ filteredSystems, auxFilteredSystems });
    if (!opValue || value === "filter-staffII") {
      await setUserFilters(true, this.state.selectedFilter, value);
      this.setState({ value: null });
    }
  };

  handleCenterFilter = async (e, opValue) => {
    let filteredSystems = [];
    let value;

    if (e) {
      e.preventDefault();
      value = e.target.value;
    } else {
      value = opValue;
    }

    this.state.companySystems.forEach((system) => {
      if (system.systemAddress.costCenter === value)
        filteredSystems.push(system);
    });

    if (value === "filter-center") filteredSystems = this.state.companySystems;
    let auxFilteredSystems = filteredSystems;

    filteredSystems = this.sortSystems(filteredSystems).sortedSystems;
    this.setState({ filteredSystems, auxFilteredSystems });
    if (!opValue || value === "filter-center") {
      await setUserFilters(true, this.state.selectedFilter, value);
      this.setState({ value: null });
    }
  };

  handleRegionFilter = async (e, opValue) => {
    let filteredSystems = [];
    let value;

    if (e) {
      e.preventDefault();
      value = e.target.value;
    } else {
      value = opValue;
    }

    this.state.companySystems.forEach((system) => {
      if (system.systemAddress.region === value) filteredSystems.push(system);
    });

    if (value === "filter-region") filteredSystems = this.state.companySystems;
    let auxFilteredSystems = filteredSystems;

    filteredSystems = this.sortSystems(filteredSystems).sortedSystems;
    this.setState({ filteredSystems, auxFilteredSystems });
    if (!opValue || value === "filter-region") {
      await setUserFilters(true, this.state.selectedFilter, value);
      this.setState({ value: null });
    }
  };

  handleFilters = async (e) => {
    const filteredSystems = this.sortSystems(
      this.state.companySystems
    ).sortedSystems;
    this.setState({ filteredSystems });

    if (e.target.value === "Vice President") {
      this.setState({ selectedFilter: "Vice President" });
    } else if (e.target.value === "Regional Manager") {
      this.setState({ selectedFilter: "Regional Manager" });
    } else if (e.target.value === "Staff I") {
      this.setState({ selectedFilter: "Staff I" });
    } else if (e.target.value === "Staff II") {
      this.setState({ selectedFilter: "Staff II" });
    } else if (e.target.value === "Cost Center") {
      this.setState({ selectedFilter: "Cost Center" });
    } else if (e.target.value === "Region") {
      this.setState({ selectedFilter: "Region" });
    } else if (e.target.value === "Remove Filters") {
      this.setState({
        value: null,
        selectedFilter: "Filters",
        showFilter: false,
      });
      await setUserFilters(false, null, null);
    } else {
      this.setState({ selectedFilter: "Filters", value: null });
      await setUserFilters(false, null, null);
    }
  };

  handleSortButton = () => {
    const systems = this.state.filteredSystems;
    const sortedSystems = this.sortSystems(systems);
    this.setState({
      filteredSystems: sortedSystems.sortedSystems,
      matchSorted: sortedSystems.matchSorted,
    });
  };

  sortSystems = (systems, flag) => {
    let filteredSystems = systems;
    let systemsName = [];
    let sortedSystemsName = [];
    let sortedSystems = [];

    filteredSystems.map((system) => {
      systemsName.push(system.systemID.name);
    });

    console.log("Checking systems name", systemsName);
    sortedSystemsName = [...systemsName];
    sortedSystemsName.sort();
    console.log("Checking arrays", sortedSystemsName, systemsName);

    let matchSorted = false;
    // if (!flag) {
    //   if (JSON.stringify(sortedSystemsName) === JSON.stringify(systemsName))
    //     sortedSystemsName.reverse();
    // }
    sortedSystemsName.map((systemName, i) => {
      if (systemName !== systemsName[i]) return (matchSorted = false);
      matchSorted = true;
    });

    if (matchSorted && !flag) sortedSystemsName.reverse();

    sortedSystemsName.map((systemName) => {
      let systemObj = filteredSystems.find(
        (system) => system.systemID.name === systemName
      );
      if (systemObj) sortedSystems.push(systemObj);
    });

    return {
      matchSorted,
      sortedSystems,
    };
  };
  /*******************************************************/

  render() {
    if (this.state.systemCount === 0)
      return (
        <React.Fragment>
          {this.state.mustLoginAgain && <Redirect from="/" exact to="/login" />}
          <p
            className="basicParagraph"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "800px",
              zIndex: 1,
            }}
          >
            {" "}
            There are no systems registered to:
          </p>
          <table
            className="table"
            style={{
              width: "350px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "180px",
              marginBottom: "800px",
              zIndex: 1,
            }}
          >
            <thead>
              <tr>
                <td>{this.props.match.params.id}</td>
              </tr>

              <div>
                <tr>
                  <td>
                    {" "}
                    Register a new board
                    <Link style={{ marginLeft: "20px" }} to="/systems/newBoard">
                      <FontAwesomeIcon
                        icon="plus-circle"
                        size="lg"
                        color="blue"
                        title="Register a new system"
                      />
                    </Link>
                  </td>

                  <td>
                    {" "}
                    Register a new product
                    <Link
                      style={{ marginLeft: "20px" }}
                      to="/systems/newProduct"
                    >
                      <FontAwesomeIcon
                        icon="plus-circle"
                        size="lg"
                        color="blue"
                        title="Register a new system"
                      />
                    </Link>
                  </td>
                </tr>
              </div>
              {/* )}*/}
            </thead>
          </table>
          <BackGroundRectangle />
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <ScrollView style={{ height: "100vh" }}>
          {this.state.mustLoginAgain && <Redirect from="/" exact to="/login" />}
          <div
            style={{
              // width: "250px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "120px",

              overflow: "auto",
              height: "inherit",
              display: "block",
              marginBottom: "200px",
              paddingBottom: "100px",
              overflowY: "scrollY",
              zIndex: 1,
            }}
          >
            {/*}  {this.state.firstLoading && (
            <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary m-2" role="status"></div>
            </div>
        )}*/}

            <table
              className="table"
              style={{ width: "350px", margin: "0px", padding: "0px" }}
            >
              <tbody>
                {this.props.match.params.id === "Raley's" && (
                  <td style={{ paddingTop: "20px", margin: "0px" }}>
                    <img
                      className="simplImage"
                      src={RaleysImage}
                      width="80"
                      height="42"
                      alt=""
                    />
                  </td>
                )}
                {this.props.match.params.id === masterCompany && (
                  <td
                    style={{
                      paddingTop: "20px",
                      margin: "0px",
                      border: "none",
                    }}
                  >
                    <img
                      className="simplImage"
                      src={masterCompanyLogo}
                      width="80"
                      height="42"
                      alt=""
                    />
                  </td>
                )}
                <td style={{ float: "right", border: "none" }}>
                  <tr
                    style={{
                      height: "10px",
                      color: "orange",
                    }}
                  >
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        textAlign: "left",
                        paddingRight: "15px",
                      }}
                    >
                      All Solar Power
                    </td>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        paddingRight: "3px",
                        paddingLeft: "5px",
                        textAlign: "right",
                      }}
                    >
                      {this.state.powerGenerated}
                    </td>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        textAlign: "left",
                      }}
                    >
                      MW
                    </td>
                  </tr>
                  <tr style={{ height: "10px", color: "magenta" }}>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        textAlign: "left",
                        paddingRight: "15px",
                      }}
                    >
                      All Grid Power
                    </td>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        paddingRight: "3px",
                        paddingLeft: "5px",
                        textAlign: "right",
                      }}
                    >
                      {this.state.powerGrid}
                    </td>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        textAlign: "left",
                      }}
                    >
                      MW
                    </td>
                  </tr>
                  <tr style={{ height: "10px", color: "green" }}>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        textAlign: "left",
                        paddingRight: "15px",
                      }}
                    >
                      All Bldgs Power
                    </td>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        paddingRight: "3px",
                        paddingLeft: "5px",
                        textAlign: "right",
                      }}
                    >
                      {this.state.powerUsed}
                    </td>
                    <td
                      style={{
                        padding: "0px",
                        margin: "0px",
                        textAlign: "left",
                      }}
                    >
                      MW
                    </td>
                  </tr>
                </td>
              </tbody>

              {this.props.match.params.id !== "Raley's" &&
                this.props.match.params.id !== masterCompany && (
                  <thead style={{ fontSize: "25px" }}>
                    {this.props.match.params.id}
                  </thead>
                )}
            </table>

            {/********************** Optional fixed table **************************/}

            {/*------------------------------------------------------------------*/}

            <br />

            {/********************** Search Bar **************************/}
            <div>
              <form
                onSubmit={(e) => this.handleSearch(e)}
                style={{ margin: "0px" }}
              >
                <label
                  style={{
                    // width: "180px",
                    fontFamily: "arial",
                    fontWeight: "550",
                    color: "#2d5c88",
                    fontSize: "10px",
                    paddingBottom: "0px",
                    marginRight: "35px",
                  }}
                >
                  Search by System Name
                </label>
                <input
                  style={{
                    width: "200px",
                    fontFamily: "arial",
                    fontWeight: "550",
                    color: "#784feb",
                    fontSize: "12px",
                    border: "2px solid rgb(206, 204, 204)",
                    borderRadius: "4px",
                    height: "30px",
                    paddingBottom: "0px",
                    marginBottom: "19px",
                  }}
                  type="text"
                  name="searchValue"
                  onChange={(e) => this.handleSearch(e)}
                />
              </form>
            </div>
            {this.state.showFilter && (
              <React.Fragment>
                <div className="d-flex flex-column">
                  <div>
                    <label
                      className="basicParagraph"
                      style={{
                        height: "30px",
                        width: "150px",
                        fontFamily: "arial",
                        fontWeight: "550",
                        color: "#2d5c88",
                        fontSize: "10px",
                      }}
                    >
                      Filter by
                    </label>
                    <select
                      // name="WTF3"
                      onChange={(e) => this.handleFilters(e)}
                      className="form-control2"
                      name="filterBy"
                      value={this.state.selectedFilter}
                      id="WTF2"
                    >
                      <option value="Filters" />
                      {this.state.filters.map((filter, index) => (
                        <option key={index} value={filter}>
                          {filter}
                        </option>
                      ))}
                      <option value="Remove Filters">Remove filters</option>
                    </select>
                  </div>

                  {this.state.selectedFilter === "Vice President" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                          fontFamily: "arial",
                          fontWeight: "550",
                          color: "#2d5c88",
                          fontSize: "10px",
                        }}
                      >
                        Filter by Vice President
                      </label>
                      <select
                        name="vicePresident"
                        defaultValue={this.state.value}
                        className="form-control2"
                        onChange={(e) => this.handleVicePresidentFilter(e)}
                        id="WTF2"
                      >
                        <option value="filter-vp" />
                        {this.state.vicePresidents.map((vp) => (
                          <option key={vp} value={vp}>
                            {vp}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {this.state.selectedFilter === "Regional Manager" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                          fontFamily: "arial",
                          fontWeight: "550",
                          color: "#2d5c88",
                          fontSize: "10px",
                        }}
                      >
                        Filter by Manager
                      </label>
                      <select
                        name="WTF3"
                        onChange={(e) => this.handleManagerFilter(e)}
                        className="form-control2"
                        defaultValue={this.state.value}
                        id="WTF2"
                      >
                        <option value="filter-manager" />
                        {this.state.regionalManagers.map((rm) => (
                          <option key={rm} value={rm}>
                            {rm}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {this.state.selectedFilter === "Staff I" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                          fontFamily: "arial",
                          fontWeight: "550",
                          color: "#2d5c88",
                          fontSize: "10px",
                        }}
                      >
                        Filter by Staff I
                      </label>
                      <select
                        name="WTF3"
                        onChange={(e) => this.handleStaffIFilter(e)}
                        className="form-control2"
                        defaultValue={this.state.value}
                        id="WTF2"
                      >
                        <option value="filter-staffI" />
                        {this.state.regionalStaffsI.map((staff) => (
                          <option key={staff} value={staff}>
                            {staff}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {this.state.selectedFilter === "Staff II" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                          fontFamily: "arial",
                          fontWeight: "550",
                          color: "#2d5c88",
                          fontSize: "10px",
                        }}
                      >
                        Filter by Staff II
                      </label>
                      <select
                        name="WTF3"
                        onChange={(e) => this.handleStaffIIFilter(e)}
                        className="form-control2"
                        defaultValue={this.state.value}
                        id="WTF2"
                      >
                        <option value="filter-staffII" />
                        {this.state.regionalStaffsII.map((staff) => (
                          <option key={staff} value={staff}>
                            {staff}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {this.state.selectedFilter === "Cost Center" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                          fontFamily: "arial",
                          fontWeight: "550",
                          color: "#2d5c88",
                          fontSize: "10px",
                        }}
                      >
                        Filter by Cost Center
                      </label>
                      <select
                        name="WTF3"
                        onChange={(e) => this.handleCenterFilter(e)}
                        className="form-control2"
                        defaultValue={this.state.value}
                        id="WTF2"
                      >
                        <option value="filter-center" />
                        {this.state.costCenters.map((center) => (
                          <option key={center} value={center}>
                            {center}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {this.state.selectedFilter === "Region" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                          fontFamily: "arial",
                          fontWeight: "550",
                          color: "#2d5c88",
                          fontSize: "10px",
                        }}
                      >
                        Filter by Region
                      </label>
                      <select
                        name="WTF3"
                        onChange={(e) => this.handleRegionFilter(e)}
                        className="form-control2"
                        defaultValue={this.state.value}
                        id="WTF2"
                      >
                        <option value="filter-region" />
                        {this.state.regions.map((region) => (
                          <option key={region} value={region}>
                            {region}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>

                <br />
              </React.Fragment>
            )}
            {/************************************************************/}
            <table className="table" style={{ width: "350px" }}>
              <thead>
                <tr>
                  <th className="col-2">
                    <FontAwesomeIcon
                      icon="satellite-dish"
                      size="lg"
                      color="gray"
                      title="Communication Signal"
                    />
                  </th>
                  <th
                    className="col-5"
                    style={{
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                    onClick={this.handleSortButton}
                  >
                    {!this.state.matchSorted && (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        size="lg"
                        color="gray"
                        title="asc-order"
                        style={{
                          marginRight: "5px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      />
                    )}
                    {this.state.matchSorted && (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        size="lg"
                        color="gray"
                        title="desc-order"
                        style={{
                          marginRight: "5px",
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      />
                    )}
                    System Name{" "}
                  </th>
                  {/* <th
                    className="col-3"
                    style={{
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                  >
                    Type
                  </th> */}
                  {/*<th style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                  Status
                </th>
                <th></th> <th></th>*/}
                  <th className="col-5">
                    <AdminMenu
                      onClickingMenu={this.handleClickMenu}
                      onChoosingListView={this.handleListViewMenu}
                      onChoosingBoard={this.handleBoardMenu}
                      onChoosingUninstallBoard={this.handleUninstallBoardMenu}
                      onChoosingProduct={this.handleProductMenu}
                      onChoosingUser={this.handleUserMenu}
                      onChoosingFleet={this.handleFleet}
                      onChoosingEscape={this.handleEscapeMenu}
                      onChoosingAccess={this.handleAcessControl}
                      onChoosingHistory={this.handleHistoryControl}
                      onChoosingFilter={this.handleFilterControl}
                      onchoosingManage={this.handleManageUsers}
                      anchorState={this.state.anchorEl}
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {this.state.filteredSystems.map((system) => (
                  <tr
                    key={system.systemID.id}
                    style={{ height: "10px" }}
                    onClick={() =>
                      this.handleRowClick(
                        `${system.systemBasics.systemConfiguration}`,
                        `${system.systemID.id}`,
                        `${this.props.match.params.id}`,
                        `${system.systemID.SN}`,
                        `${system.systemID.name}`
                      )
                    }
                  >
                    <td>
                      {/* {this.state.firstLoading && (
                        <div class="d-flex justify-content-center">
                          <div
                            class="spinner-grow text-success spinner-grow-sm"
                            role="status"
                          ></div>
                        </div>
                      )} */}

                      {(system.systemConnection === null ||
                        system.systemConnection === 2) && (
                        <button className="led-red-on">
                          {" "}
                          {system.systemStatus === "SUSPENDED" && (
                            <p style={{ fontWeight: "bold" }}>S</p>
                          )}
                        </button>
                      )}
                      {system.systemConnection === 1 && (
                        <button className="led-yellow-on">
                          {" "}
                          {system.systemStatus === "SUSPENDED" && (
                            <p style={{ fontWeight: "bold" }}>S</p>
                          )}
                        </button>
                      )}
                      {system.systemConnection === 0 && (
                        <button className="led-green-on">
                          {system.systemStatus === "SUSPENDED" && (
                            <p style={{ fontWeight: "bold" }}>S</p>
                          )}
                        </button>
                      )}
                    </td>
                    <td style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                      {system.systemID.name}
                    </td>
                    {/* <td
                      style={{
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    >
                      {system.systemProduct}
                    </td> */}

                    <td
                      className="icon"
                      style={{ paddingLeft: "2px", paddingRight: "2px" }}
                    >
                      <div className="d-flex justify-content-around">
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleRowClick(
                              `${system.systemBasics.systemConfiguration}`,
                              `${system.systemID.id}`,
                              `${this.props.match.params.id}`,
                              `${system.systemID.SN}`,
                              `${system.systemID.name}`,
                              "search"
                            );
                          }}
                        >
                          <FontAwesomeIcon
                            icon="search"
                            size="lg"
                            color="brown"
                            title="Search"
                          />
                        </a>
                        {system.systemBasics.systemConfiguration !== "8" && (
                          <>
                            <div style={{ width: "3px" }}> </div>
                            <FontAwesomeIcon
                              style={{ marginLeft: "3px" }}
                              icon="tachometer-alt"
                              size="lg"
                              color="green"
                              title="monitor"
                            />
                          </>
                        )}
                        {system.systemBasics.systemConfiguration === "8" && (
                          <>
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                this.handleRowClick(
                                  `${system.systemBasics.systemConfiguration}`,
                                  `${system.systemID.id}`,
                                  `${this.props.match.params.id}`,
                                  `${system.systemID.SN}`,
                                  `${system.systemID.name}`,
                                  "listview"
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon="list-ul"
                                size="lg"
                                color="dodgerblue"
                                title="Subsystems"
                              />
                            </a>
                            <FontAwesomeIcon
                              icon="bolt"
                              size="lg"
                              color="green"
                              title="Monitor SimplNodes"
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <BackGroundRectangle />
        </ScrollView>
      </React.Fragment>
    );
  }
}

export default CompanySystems;
/*
this.props.history.push(
      `/systems/simplVu/${system.systemID.id}/${this.props.match.params.id}/${system.systemID.SN}/${system.systemID.name}/companyAdmin`
    );
*/
/*
{system.systemBasics.systemConfiguration !== "8" && (
  <Link
    to={`/systems/simplVu/${system.systemID.id}/${this.props.match.params.id}/${system.systemID.SN}/${system.systemID.name}/companyAdmin`}
  >
    <FontAwesomeIcon
      icon="tachometer-alt"
      size="lg"
      color="green"
      title="monitor"
    />
  </Link>
)}
{system.systemBasics.systemConfiguration === "8" && (
  <Link
    to={`/systems/simplNodesVu/${system.systemID.id}/${this.props.match.params.id}/${system.systemID.SN}/${system.systemID.name}/companyAdmin`}
  >
    <FontAwesomeIcon
      icon="bolt"
      size="lg"
      color="green"
      title="Monitor SimplNodes"
    />
  </Link>
)}*/
