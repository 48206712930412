import React, { Component } from "react";
import { Link } from "react-router-dom";
import { setJwt } from "../services/httpService";
import { getUser, getUserSystems } from "../services/userService";
import { SystemsUsers } from './systemsUsers';
import Modal from "react-bootstrap/Modal";
import "./dashboard.css";
import "../index.css";
import ReactExport from "react-data-export";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import { toast } from "react-toastify";
import BackGroundRectangle from "../components/backgroundRect";
import { getCourses, saveCourse } from "../services/companyService";

class SimplCourses extends Component {
  state = {
    systems: [],
  };

  async componentDidMount() {
    try {
      const usernameprops = this.props.match.params.username;
      console.log("User name :", usernameprops);
      const jwt = localStorage.getItem("token");
      setJwt(jwt);
      const user = await getUser();
      console.log("User", user);
      const username = user.data.data.username;
      const firstName = user.data.data.firstName;
      const lastName = user.data.data.lastName;
      const emailAddress = user.data.data.emailAddress;
      const admin = user.data.data.admin
      this.setState({ username, firstName, lastName, emailAddress, admin });
      const conditionsAgreed = false;
      this.setState({ conditionsAgreed });
      const userSystems = (await getUserSystems()).data.data;
      console.log("Logged in User", user.data.data.username);
      console.log("Company Name", user.data.data.company);
      console.log("User Systems", userSystems);

      this.setState({ companyName: user.data.data.company});

      await getCourses().then(res => {
        this.setState({ videos: res.data.data.courses, allVideos: res.data.data.courses });
      });

      // Checking video's json
      console.log("Json Videos: ", this.state.videos);

    } catch (e){
      window.location.reload();
    }
  }

  handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    let videos = [];
    if (e.target.value === "") {
      videos = this.state.allVideos;
    } else {
      videos = this.state.allVideos.filter((video) => { 
        return video.title.toLowerCase().includes(value) ||
        video.description.toLowerCase().includes(value);
      });
    }
    this.setState({ videos });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "400px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-45%)",
            marginTop: "150px",
            paddingBottom: "100px",
            zIndex: 1,
          }}
        >
          <div 
          style={{
            marginRight: "35px"
          }}
          >
            <div 
            className="d-flex align-items-center text-center justify-content-center mb-2"
            >
              <p
                className="basicParagraph"
                style={{ fontSize: "15px", fontWeight: "bold", color: "#2d5c88" }}
              >
                Brief Video Courses:
              </p>
            </div>
            <div
              className="systemClock"
              style={{
              height: "30px",
              width: "400px",
              // display: "flex",
              // justifyContent: "center"
              marginLeft: "135px",
              // marginBottom: "15px"
              }}
            >
              <div>
                {this.state.companyName === "Simpl Global Inc." && this.state.admin && (
                  <button
                    className="btn btn-primary shadow-none"
                    style={{
                      borderWidth: "0px",
                      backgroundColor: "transparent",
                      color: "transparent",
                      marginRight: "0px",
                      marginTop: "-15px",
                    }}
                  >
                    <UploadVideoModal videos={this.state.allVideos} />
                  </button>
                )}
              </div>
            </div>
            <div
            className="systemClock" 
            style={{
              // position: "relative",
              // overflow: "hidden",
              // zIndex: "-1",
              width: "400px",
              marginLeft: "135px",
              minHeight: "45px",
              marginBottom: "15px",
              // maxHeight: `${filterOption === "Description" ? "auto" : "45px"} !important`,
              backgroundColor: "#ece9e9",
              // display: "flex",
              // flexFlow: "row nowrap",
              justifyContent: "flex-center",
              alignItems: "center"
              // paddingBottom: `${filterOption == "Description" ? "10px" : "0px"}`
            }}>
              <div className="d-flex justify-content-center align-items-center">

                <input 
                  style={{
                    width: "300px",
                    fontFamily: "arial",
                    fontWeight: "250",
                    color: "#784feb",
                    fontSize: "12px",
                    border: "2px solid rgb(206, 204, 204)",
                    borderRadius: "4px",
                    height: "30px",
                    paddingBottom: "0px",
                  }} 
                  type="text"
                  name="search" 
                  placeholder="Search by keyword"
                  onChange={(e) => this.handleSearch(e)}
                />
              </div>
            </div>

            {this.state.videos && this.state.videos.map((video) => (
              <div>
                <button
                  key={video.url.slice(17,)}
                  className="primary-btn col-xs-11 text-left"
                  style={{
                    borderWidth: "0px",
                    color: "dodgerblue",
                    backgroundColor: "transparent",
                    width: "300px",
                    marginTop: "5px"
                  }}
                  >
                  <a href={video.url}  target="_blank" rel="noopener noreferrer" 
                    style={{
                      // fontWeight: "bold",
                      // fontSize: "14px"
                    }}
                  >
                    <p className="basicParagraph">
                      {video.title}
                    </p>
                  </a>
                </button>
                <hr style={{margin: "10px"}}></hr>
              </div>
            ))}
          </div>
          {/* <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   window.location.href = "https://youtu.be/2OqtOeXP0fw";
            // }}
          >
            <a href="https://youtu.be/2OqtOeXP0fw" target="_blank" rel="noopener noreferrer">
              Logging In, Logging Out, and Registering
            </a>
          </button>
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            // onClick={(e) => {
            //   e.preventDefault();
            //   window.location.href = "https://youtu.be/5I5thCMyH3k";
            // }}
          >
            <a href="https://youtu.be/5I5thCMyH3k" target="_blank" rel="noopener noreferrer">
              SimplControls Hardware Overview
            </a>
          </button>
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://youtu.be/yjhMgpW-IBo";
            }}
          >
            Changing Thermostats Settings: Two Methods
          </button>
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://youtu.be/YweoiHAYZ7w";
            }}
          >
            Set System Schedules and Hours of Operation
          </button>
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://youtu.be/qxqoE5QqExQ";
            }}
          >
            Sensing Room Temperature: Two Methods
          </button>
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://youtu.be/y_wk8jvJhTY";
            }}
          >
            Scheduling for Exterior Lights
          </button>{" "}
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://youtu.be/VQfChzXxutc";
            }}
          >
            Lighting Schedule Using Sunset and Sunrise w Delay
          </button>
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://youtu.be/GobwG-MhCus";
            }}
          >
            Alerts Setup and Edits
          </button>
          <hr></hr>
          <button
            className="primary-btn col-xs-11 text-left"
            style={{
              borderWidth: "0px",
              color: "dodgerblue",
              backgroundColor: "transparent",
              width: "300px",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://youtu.be/W013MKHAb78";
            }}
          >
            Various Ways of Viewing Your Systems
          </button>{" "} */}
        </div>
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

export default SimplCourses;

class UploadVideoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      loaded: true
    };

    this.onClickHandler = async () => {
      const videos = this.props.videos;
      const newVideo = {
        "title": this.state.title,
        "url": this.state.url,
        "description": this.state.description
      }
      videos.push(newVideo);
      await saveCourse(videos)
      .then(res => { // then print response status
        console.log(res.statusText);
        if (res.statusText === 'OK') toast.success("Video Saved!");
        // this.setState({ allVideos: videos, videos });
        this.handleHide();
      })
    }

    this.title = (e) => {
      this.setState({title: e.target.value});
      console.log("title", this.state.title);
    }

    this.url = (e) => {
      this.setState({url: e.target.value});
      console.log("desc", this.state.url);
    }
    
    this.description = (e) => {
      this.setState({description: e.target.value});
    }

    this.handleShow = () => {
      this.setState({ show: true });
    };
    
    this.handleHide = () => {
      this.setState({ show: false });
    };
  }
  
  render() {
    console.log("videos", this.props.videos);
    return (
      <>
        <button
          onClick={this.handleShow}
          className="btn btn-shadow-none"
          style={{ padding: "0" }}
        >
          <FontAwesomeIcon
            icon="plus-circle"
            size="2x"
            color="#ffc000"
            title="Save new video"
          />
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Save New Video
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <div 
              className="d-flex justify-content-around mb-3"
            >
                <label className="basicParagraph" style={{width: "150px"}}>Enter the title here: </label>
                <input 
                type="text" 
                name="Title" 
                onChange={e => this.title(e)} 
                // style={{width: "180px", marginLeft: "10px"}}
                style={{
                  width: "200px",
                  fontFamily: "arial",
                  fontWeight: "250",
                  color: "#784feb",
                  fontSize: "12px",
                  border: "2px solid rgb(206, 204, 204)",
                  borderRadius: "4px",
                  // height: "30px",
                  paddingBottom: "0px",
                  placeholder: "Required"
                }}
                />
            </div>
            <div 
              className="d-flex justify-content-around mb-3"
            >
                <label className="basicParagraph" style={{width: "150px"}}>Enter the url here: </label>
                <input 
                type="text" 
                name="Title" 
                onChange={e => this.url(e)} 
                // style={{width: "180px", marginLeft: "10px"}}
                style={{
                  width: "200px",
                  fontFamily: "arial",
                  fontWeight: "250",
                  color: "#784feb",
                  fontSize: "12px",
                  border: "2px solid rgb(206, 204, 204)",
                  borderRadius: "4px",
                  // height: "30px",
                  paddingBottom: "0px",
                  placeholder: "Required"
                }}
                />
            </div>
            <div 
              className="d-flex justify-content-around mb-3"
            >
                <label className="basicParagraph" style={{width: "150px"}}>Enter a brief description: </label>
                <textarea 
                type="text" 
                name="Title" 
                onChange={e => this.description(e)} 
                // style={{width: "180px", marginLeft: "10px"}}
                style={{
                  width: "200px",
                  fontFamily: "arial",
                  fontWeight: "250",
                  color: "#784feb",
                  fontSize: "12px",
                  border: "2px solid rgb(206, 204, 204)",
                  borderRadius: "4px",
                  // height: "30px",
                  paddingBottom: "0px",
                  placeholder: "Required"
                }} 
                />
            </div>
            {/* <button type="button" className="btn btn-success btn-block" onClick={this.onClickHandler} disabled={this.state.loaded ? false : true}>Save</button> */}
            <button
                // className="btn btn-primary shadow-none"
                className="btn btn-outline-success btn-md"
                style={{
                }}
                onClick={this.onClickHandler}
                disabled={this.state.loaded ? false : true}
              >
                Save
              </button>
            {!this.state.loaded && (
              <p>Video is being saved, please wait.</p>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
