import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  masterCompany,
  headerLogoSource,
  manufacturingCompany,
} from "../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zIndex } from "@material-ui/core/styles/zIndex";

const NavBar = ({
  user,
  firstName,
  lastName,
  companyName,
  isAdmin,
  systemFlag,
  mapState,
  verified,
  mfa,
}) => {
  console.log("User in Navbar", user);
  if (user) {
    console.log("User Name", user);
    console.log("Company Name", companyName);
    console.log("Is Admin", isAdmin);
    console.log("System Flag", systemFlag);
    console.log("Map State", mapState);
  }
  const toHome =
    isAdmin && companyName === "Simpl Global Inc."
      ? `/Companies`
      : `/users/systems/${user}`;
  return (
    <div>
      <nav
        className="navbarRibbon"
        style={{
          position: "fixed",
          width: "100%",
          height: "100px",
          zIndex: "10",
        }}
      >
        <Link
          to={toHome}
          style={{
            position: "fixed",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <img src={headerLogoSource} width="120" height="66" alt="" />
        </Link>

        <div
          className="navbar"
          style={{
            height: "40px",
            transform: "translateY(180%)",
          }}
        >
          <table
            style={{
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",
              height: "50px",
              marginTop: "22px",
              zIndex: "10",
            }}
          >
            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName === manufacturingCompany && (
                <td>
                  <Link to="/manufactures">
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                </td>
              )}

            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName !== manufacturingCompany &&
              companyName !== masterCompany &&
              isAdmin &&
              !mapState && (
                <td>
                  <Link to={`/Companies/systems/${companyName}`}>
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                  <Link to={`/courses/${user}`} style={{ marginLeft: "20px" }}>
                    <FontAwesomeIcon
                      icon="chalkboard-teacher"
                      size="lg"
                      color="orange"
                      title="Courses"
                    />
                  </Link>
                </td>
              )}
            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName !== manufacturingCompany &&
              companyName !== masterCompany &&
              isAdmin &&
              mapState && (
                <td>
                  <Link to={`/systems/simplMap`}>
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                  <Link to={`/courses/${user}`} style={{ marginLeft: "20px" }}>
                    <FontAwesomeIcon
                      icon="chalkboard-teacher"
                      size="lg"
                      color="orange"
                      title="Courses"
                    />
                  </Link>
                </td>
              )}

            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName !== manufacturingCompany &&
              companyName !== { masterCompany }.masterCompany &&
              !isAdmin &&
              systemFlag && (
                <td>
                  {" "}
                  <Link to={`/users/systems/${user}`}>
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                  <Link to={`/courses/${user}`} style={{ marginLeft: "20px" }}>
                    <FontAwesomeIcon
                      icon="chalkboard-teacher"
                      size="lg"
                      color="orange"
                      title="Courses"
                    />
                  </Link>
                </td>
              )}

            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName !== manufacturingCompany &&
              companyName !== { masterCompany }.masterCompany &&
              !isAdmin &&
              !systemFlag && (
                <td>
                  {" "}
                  <Link from="/" exact to="/accountInfo">
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                  <Link to={`/courses/${user}`} style={{ marginLeft: "20px" }}>
                    <FontAwesomeIcon
                      icon="chalkboard-teacher"
                      size="lg"
                      color="orange"
                      title="Courses"
                    />
                  </Link>
                </td>
              )}

            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName !== manufacturingCompany &&
              companyName === { masterCompany }.masterCompany &&
              isAdmin && (
                <td>
                  {" "}
                  <Link to="/Companies">
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                  <Link to={`/courses/${user}`} style={{ marginLeft: "20px" }}>
                    <FontAwesomeIcon
                      icon="chalkboard-teacher"
                      size="lg"
                      color="orange"
                      title="Courses"
                    />
                  </Link>
                </td>
              )}

            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName !== manufacturingCompany &&
              companyName === { masterCompany }.masterCompany &&
              !isAdmin &&
              systemFlag && (
                <td>
                  {" "}
                  <Link to={`/users/systems/${user}`}>
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                  <Link to={`/courses/${user}`} style={{ marginLeft: "20px" }}>
                    <FontAwesomeIcon
                      icon="chalkboard-teacher"
                      size="lg"
                      color="orange"
                      title="Courses"
                    />
                  </Link>
                </td>
              )}

            {user &&
              ((!verified && !mfa) || (verified && mfa)) &&
              companyName !== manufacturingCompany &&
              companyName === { masterCompany }.masterCompany &&
              !isAdmin &&
              !systemFlag && (
                <td>
                  {" "}
                  <Link to="/login">
                    <FontAwesomeIcon
                      icon="home"
                      size="lg"
                      color="orange"
                      title="Home"
                    />
                  </Link>
                  <Link to={`/courses/${user}`} style={{ marginLeft: "20px" }}>
                    <FontAwesomeIcon
                      icon="chalkboard-teacher"
                      size="lg"
                      color="orange"
                      title="Courses"
                    />
                  </Link>
                </td>
              )}

            {user && ((!verified && !mfa) || (verified && mfa)) && (
              <Link
                style={{ marginLeft: "20px" }}
                className="navLink"
                to="/accountInfo"
              >
                {firstName} {lastName}
              </Link>
            )}
          </table>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
